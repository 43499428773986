import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Navbar/Navbar.css";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown = useCallback(() => {
    setDropdownOpen((prev) => !prev);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, []);

  const closeMenu = () => {
    setMenuOpen(false);
    setDropdownOpen(false); // Close the dropdown too if it's open
  };

  return (
    <>

     <div className="desktop">
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className={`nav-links ${menuOpen ? "open" : ""}`}>
        <Link to="/shop" onClick={closeMenu}>
          Products
        </Link>
        <Link to="/blog" onClick={closeMenu}>
          Blogs
        </Link>
        <a href="/ingredients" onClick={closeMenu}>
          Ingredients
        </a>
        <a href="/about" onClick={closeMenu}>
          About
        </a>
      </div>

      <Link to="/">
        <div className="logo">
          <img src="/logo.png" className="logo-h" alt="logo" />
        </div>
      </Link>

      <div className={`nav-links ${menuOpen ? "open" : ""}`}>
        <a href="/" onClick={closeMenu}>
          Home
        </a>

        <a href="/consultation" onClick={closeMenu}>
          Book Your Consultation
        </a>
      </div>

      {/* Hamburger Menu */}
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
    </div>
    <div className="mobile">
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
    

      <Link to="/">
        <div className="logo">
          <img src="/logo.png" className="logo-h" alt="logo" />
        </div>
      </Link>

      <div className={`nav-links ${menuOpen ? "open" : ""}`}>
        <a href="/" onClick={closeMenu}>
          Home
        </a>
        <Link to="/shop" onClick={closeMenu}>
          Products
        </Link>
        <Link to="/blog" onClick={closeMenu}>
          Blogs
        </Link>
        <a href="/ingredients" onClick={closeMenu}>
          Ingredients
        </a>
        <a href="/about" onClick={closeMenu}>
          About
        </a>
        <a href="/consultation" onClick={closeMenu}>
          Book Your Consultation
        </a>
       
      </div>

      {/* Hamburger Menu */}
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
    </div>
    </>
  );
};

export default Navbar;
