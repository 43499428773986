import React, { useState } from 'react';
import Slider from 'react-slick';
import { IoLogoInstagram } from "react-icons/io"; 
import { FaPlay, FaPause } from "react-icons/fa";  // Import FaPause icon
import '../Mimg/Mimg.css'; 

const Mimg = () => {
  const videos = [
    { src: '/joyful.mp4', thumbnail: '/i1.jpeg', instagramLink: 'https://www.instagram.com/reel/DAbF_D0vS60/?igsh=MWw4aG5pc3hib2Rwdg%3D%3D' },
    { src: '/facts.mp4', thumbnail: '/i2.jpeg', instagramLink: 'https://www.instagram.com/reel/C_7yCnWPRzT/?igsh=cGJpcmFybHpzbjJr' },
    { src: '/inex.mp4', thumbnail: '/i3.jpeg', instagramLink: 'https://www.instagram.com/reel/DATMT6xPMeY/' },
    { src: '/uncovering.mp4', thumbnail: '/i4.jpeg', instagramLink: 'https://www.instagram.com/reel/C_5c0IpPJPY/?igsh=Y2d6czJ6cjI0eWQ4' },
    { src: '/new.mp4', thumbnail: '/i5.jpeg', instagramLink: 'https://www.instagram.com/reel/DAYTjKhP2Iw/' },
    { src: '/happy.mp4', thumbnail: '/i6.jpeg', instagramLink: 'https://www.instagram.com/reel/DABLJt1v2vR/?igsh=MWdiZnNhOWR2dnF3dQ%3D%3D' },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const openInstagram = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const [playing, setPlaying] = useState({});

  const handlePlayPause = (index) => {
    setPlaying((prev) => ({ ...prev, [index]: !prev[index] }));  // Toggle play/pause
  };

  return (
    <div className="mimg-container">
      <h1 className='mimg-head'>Instagram</h1>
      <Slider {...settings}>
        {videos.map((video, index) => (
          <div 
            key={index} 
            className={`image-slide ${index % 2 === 0 ? 'large-slide' : ''}`}
          >
            <div className="image-overlay">
              <div className="media-container">
                {playing[index] ? (
                  <video 
                    className="slider-image" 
                    controls 
                    autoPlay
                    onEnded={() => setPlaying((prev) => ({ ...prev, [index]: false }))}
                  >
                    <source src={video.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img 
                    src={video.thumbnail} 
                    alt={`Thumbnail ${index + 1}`} 
                    className="slider-image thumbnail" 
                  />
                )}
              </div>

              <div className="overlay-m">
                <button 
                  className="shop-now-btn play-btn" 
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    handlePlayPause(index);  // Toggle play/pause
                  }}
                  aria-label={playing[index] ? "Pause Video" : "Play Video"}
                  onMouseEnter={(e) => e.stopPropagation()}  // To prevent play button from disappearing on hover
                >
                  {playing[index] ? (
                    <FaPause className='btn-ic' />  // Show pause icon when video is playing
                  ) : (
                    <FaPlay className='btn-ic' />   // Show play icon when video is paused
                  )}
                </button>
              </div>
            </div>

            <div className="instagram-button-container">
              <button 
                className="shop-now-btn2 instagram-btn" 
                onClick={(e) => { 
                  e.stopPropagation(); 
                  openInstagram(video.instagramLink); 
                }}
                aria-label="Open Instagram"
              >
                <IoLogoInstagram className='btn-ic2'/>
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Mimg;
