import React, { useState } from "react";
import "../Lazyimage/Lazyimage.css"; 

function LazyImage({ src, alt, className, ...props }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false); // Optional: Handle broken image case
  };

  return (
    <div className="image-wrapper">
      {isLoading && (
        <div className="loader-container">
          <img
            src="loader.gif"
            alt="Loading..."
            className="loading-animation"
          />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={`${className} ${isLoading ? "hidden" : "visible"}`}
        onLoad={handleLoad}
        onError={handleError}
        {...props}
      />
    </div>
  );
}

export default LazyImage;
