import React from "react";
import "../Disclaimer/Disclaimer.css";

const Disclaimer = () => {
  const handleMailClick = (event) => {
    event.preventDefault();

    window.location.href = "mailto:hello@3eskinroute.com";
  };
  return (
    <div className="disclaimer-section">
      <div className="disclaimer-content">
        <h1 className="disclaimer-title">Disclaimer</h1>
        <p className="disclaimer-text">
          The information provided on the 3ESkinRoute website, including but
          not limited to product descriptions, recommendations, and educational
          content, is intended for general informational purposes only. The
          content on this website, including product descriptions, blog posts,
          and educational materials, is not intended as medical advice,
          diagnosis, or treatment. While we strive to ensure that the
          information is accurate and up-to-date, it is not intended as
          professional or medical advice. Please consult with a healthcare
          professional before using any cosmetic product, especially if you have
          sensitive skin, allergies, or specific skin conditions.
        </p>
        <p className="disclaimer-text">
          Our plant-based cosmetic formulations are designed with care and
          attention to individual needs. However, individual results may vary.
          Please conduct a patch test before use, especially if you have known
          allergies or sensitivities.
        </p>
        <p className="disclaimer-text">
          3ESkinRoute assumes no responsibility for any adverse reactions or
          allergic responses, issues, or damages that may result from the use of
          our products. Use of our products is at your own risk.
        </p>
        <p className="disclaimer-text">
          By using our website and purchasing our products, you agree to this
          disclaimer and our Terms and Conditions. 3ESkinRoute reserves the
          right to make changes to the website and the content herein at any
          time, without notice.
        </p>
        <p className="disclaimer-text">
          If you have any concerns or questions regarding this disclaimer,
          please contact us at{" "}
          <span onClick={handleMailClick} className="disclaimer-link">
          hello@3eskinroute.com
          </span>
          .
        </p>

        <h2 className="disclaimer-subtitle">Shipping and Return Policy</h2>
        <p className="disclaimer-text">
          At 3ESkinRoute, we are committed to delivering your plant-based
          personal care products safely and efficiently. Please review the
          following policy regarding shipping, returns, and damages.
        </p>

        <h3 className="disclaimer-heading">Shipping Policy</h3>
        <ul className="disclaimer-list">
          <li className="disclaimer-list-item">
            We offer standard shipping within India.
          </li>
          <li className="disclaimer-list-item">
            Orders are typically processed within 2-3 business days and
            delivered within 7-10 business days depending on your location.
          </li>
          <li className="disclaimer-list-item">
            You will receive a confirmation email.
          </li>
          <li className="disclaimer-list-item">
            Shipping charges are calculated at checkout and vary based on
            location and order weight.
          </li>
        </ul>

        <h3 className="disclaimer-heading">Non-Returnable Products</h3>
        <p className="disclaimer-text">
          For hygiene and safety reasons, personal care products (including
          skincare, cosmetics, and other body care items) are non-returnable
          once opened or used. We encourage you to carefully review product
          descriptions and ingredients before making a purchase. If you have any
          questions about a product, feel free to contact us before placing your
          order.
        </p>

        <h3 className="disclaimer-heading">Damaged or Defective Items</h3>

        <p className="disclaimer-text">
          In the event that your order arrives damaged due to courier handling,
          please follow these steps:
        </p>
        <ol className="disclaimer-list-ordered">
          <li className="disclaimer-list-item">
            Report the damage within 2 days of delivery: You must contact our
            customer service team at (7394011335) within 2 days
            of receiving the delivery.
          </li>
          <li className="disclaimer-list-item">
            Provide proof of damage: Please send us a video of the outer
            packaging and all the products received, clearly showing the damage.
          </li>
          <li className="disclaimer-list-item">
            Once we receive and verify the evidence, we will offer a replacement
            or refund at our discretion. Please note that failure to provide the
            required video proof within the specified time frame will result in
            rejection of the claim.
          </li>
        </ol>

        <h3 className="disclaimer-heading">Return Policy</h3>
        <ul className="disclaimer-list">
          <li className="disclaimer-list-item">
            Non-returnable items: As stated above, personal care products are
            non-returnable unless they arrive damaged or defective.
          </li>
          <li className="disclaimer-list-item">
            If you receive an incorrect or defective product, please notify us
            within 2 days of delivery.
          </li>
          <li className="disclaimer-list-item">
            You need to make a package opening video with outer package
            uncovering and box unpacking and send it to us.
          </li>
          <li className="disclaimer-list-item">
            We will then guide you through the return process, and a replacement
            or refund will be issued accordingly.
          </li>
        </ul>

        <h3 className="disclaimer-heading">Order Cancellations</h3>
        <ul className="disclaimer-list">
          <li className="disclaimer-list-item">
            Orders can only be canceled before they are shipped. Once an order
            has been dispatched, it cannot be canceled or refunded.
          </li>
          <li className="disclaimer-list-item">
            To cancel your order, please contact us at 7394011335 as soon as
            possible.
          </li>
        </ul>

        <h3 className="disclaimer-heading">Shipping Delays</h3>
        <p className="disclaimer-text">
          While we strive to meet the estimated delivery times, 3ESkinRoute is
          not responsible for delays caused by couriers, customs, or other
          unforeseen circumstances.
        </p>

        <p className="disclaimer-text">
          For any questions regarding shipping, returns, or damages, feel free
          to contact our customer service team at{" "}
          <span onClick={handleMailClick} className="disclaimer-link">
            hello@3eskinroute.com
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
