import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Blog from './Components/Blog/Blog';
import BlogDetail from './Components/Blog/BlogDetail';
import About from './Components/About/About';
import Ingredents from './Components/Ingredents/Ingredents';
import CardDetails from './Components/About/CardDetails';
import Consultation from './Components/Contact/Consultation';
import Shop from './Components/Shop/Shop';
import Chatbox from './Components/Chatbox/Chatbox';
import "aos/dist/aos.css";
import AOS from "aos";
import ShopDetails from './Components/Shop/Shopdetails';
import Disclaimer from './Components/Disclaimer/Disclaimer';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    AOS.refresh();

    const handleKeyDown = (event) => {
      if (
        event.keyCode === 123 || 
        (event.ctrlKey && event.shiftKey && ['I', 'J'].includes(event.key)) || 
        (event.ctrlKey && event.key === 'U')
      ) {
        event.preventDefault();
      }
    };

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
      AOS.refresh();
    };
  }, []);

  return (
    <Router>  
      <Navbar />
      <ScrollToTop />
      
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Helmet>
                <title>Home | 3ESkinRoute</title>
                <link rel="canonical" href="https://3eskinroute.com/" />
              </Helmet>
              <Home />
            </>
          } 
        />
        <Route 
          path="/blog" 
          element={
            <>
              <Helmet>
                <title>Blog | 3ESkinRoute</title>
                <link rel="canonical" href="https://3eskinroute.com/blog" />
              </Helmet>
              <Blog />
            </>
          } 
        />
        <Route 
          path="/blog/:id" 
          element={
            <>
              <BlogDetailWithHelmet />
            </>
          } 
        />
        <Route 
          path="/shopdetails/:id" 
          element={
            <>
              <ShopDetailsWithHelmet />
            </>
          } 
        />
        <Route 
          path="/disclaimer" 
          element={
            <>
              <Helmet>
                <title>Disclaimer | 3ESkinRoute</title>
                <link rel="canonical" href="https://3eskinroute.com/disclaimer" />
              </Helmet>
              <Disclaimer />
            </>
          } 
        />
        <Route 
          path="/about" 
          element={
            <>
              <Helmet>
                <title>About Us | 3ESkinRoute</title>
                <link rel="canonical" href="https://3eskinroute.com/about" />
              </Helmet>
              <About />
            </>
          } 
        />
        <Route 
          path="/ingredients/:id" 
          element={
            <>
              <IngredientDetailWithHelmet />
            </>
          } 
        />
        <Route 
          path="/ingredients" 
          element={
            <>
              <Helmet>
                <title>Ingredients | 3ESkinRoute</title>
                <link rel="canonical" href="https://3eskinroute.com/ingredients" />
              </Helmet>
              <Ingredents />
            </>
          } 
        />
        <Route 
          path="/consultation" 
          element={
            <>
              <Helmet>
                <title>Consultation | 3ESkinRoute</title>
                <link rel="canonical" href="https://3eskinroute.com/consultation" />
              </Helmet>
              <Consultation />
            </>
          } 
        />
        <Route 
          path="/shop" 
          element={
            <>
              <Helmet>
                <title>Shop | 3ESkinRoute</title>
                <link rel="canonical" href="https://3eskinroute.com/shop" />
              </Helmet>
              <Shop />
            </>
          } 
        />
      </Routes>
      
      <Footer />
      <Chatbox />
    </Router>
  );
}

function BlogDetailWithHelmet() {
  const { id } = useParams(); // Get the dynamic blog ID from the URL
  return (
    <>
      <Helmet>
        <title>Blog Detail | 3ESkinRoute</title>
        <link rel="canonical" href={`https://3eskinroute.com/blog/${id}`} />
      </Helmet>
      <BlogDetail id={id} />
    </>
  );
}

function ShopDetailsWithHelmet() {
  const { id } = useParams(); // Get the dynamic product ID from the URL
  return (
    <>
      <Helmet>
        <title>Shop Details | 3ESkinRoute</title>
        <link rel="canonical" href={`https://3eskinroute.com/shopdetails/${id}`} />
      </Helmet>
      <ShopDetails id={id} />
    </>
  );
}

function IngredientDetailWithHelmet() {
  const { id } = useParams(); // Get the dynamic ingredient ID from the URL
  return (
    <>
      <Helmet>
        <title>Ingredients | 3ESkinRoute</title>
        <link rel="canonical" href={`https://3eskinroute.com/ingredients/${id}`} />
      </Helmet>
      <CardDetails id={id} />
    </>
  );
}

export default App;
