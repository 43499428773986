import React, { useState, useEffect } from "react";
import "../Slider/Slider.css";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      image:
        "slider3.avif",
      heading: "Redefining the Art of Radiance",
      description: "Feel Confident in Your Own Skin",
    },
    {
      image:
        "sliders.jpg",
      heading: "Your Journey to Skincare",
      description: "For a Radiance that’s all you",
    },
    {
      image:
        "slidern.jpg",
      heading: "Elevate your Skincare Rituals",
      description: "Luxury Products for Effortless Elegance",
    },
    {
      image: "slider4.avif",
      heading: "Are You Ready For The Glowing Skin?",
      description: "Nourish Your Skin with Nature's Best",
    },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 4000); // 3 seconds for each slide
    return () => clearInterval(slideInterval);
  }, [slides.length]);

  return (
    <div className="slider">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? "active" : ""}`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
       <div className="block block-left"></div>
  <div className="block block-middle"></div>
  <div className="block block-right"></div>
          <div className="overlayu">
            <h2>{slide.heading}</h2>
            <p>{slide.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Slider;
























