import React, { useState, useEffect } from 'react';
import '../Brands/Brands.css'; // Assuming this path is correct for your CSS file

const products = [
  {
    id: 1,
    name: "Assess",
    desc: "Take our comprehensive consultation session to help us understand your unique needs.",
    img: "assess.jpeg",
  },
  {
    id: 2,
    name: "Customize",
    desc: "Our experts craft a personalized skincare routine just for you. You will have follow-up sessions where we guide you for internal health management for well-balanced skin.",
    img: "brand2.jpg",
  },
  {
    id: 3,
    name: "Deliver",
    desc: "Receive your eco-friendly package of tailored skincare products.",
    img: "deliver.jpg",
  },
  {
    id: 4,
    name: "Glow",
    desc: "Follow your simple routine and watch your skin transform naturally.",
    img: "glow1.jpg",
  },
];

const Brands = () => {
  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [hoveredProduct, setHoveredProduct] = useState(null); // State for hovering product
  const [isZooming, setIsZooming] = useState(false);

  useEffect(() => {
    // Trigger zoom-out effect on image change
    setIsZooming(true);
    const timer = setTimeout(() => setIsZooming(false), 500); // Duration of the animation

    return () => clearTimeout(timer); // Clean up timer when component unmounts or product changes
  }, [selectedProduct]);

  // Determine which image to display: hovered or selected
  const displayedProduct = hoveredProduct || selectedProduct;

  return (
    <div className="brands-container">
      <h2 className="brands-heading">How It Works</h2>
      <div className="brands-content">
        {/* Left Side: List of Products */}
        <div className="brands-list">
          {products.map((product, index) => (
            <div
              key={index}
              className={`product-item ${selectedProduct.name === product.name ? 'active' : ''}`}
              onClick={() => setSelectedProduct(product)} // Click to select the product
              onMouseEnter={() => setHoveredProduct(product)} // Hover to temporarily display the product
              onMouseLeave={() => setHoveredProduct(null)} // Reset on mouse leave
            >
              <div className="product-desc">
                <p>{product.desc}</p>
              </div>
              <div className="prodiv">
              <div className="product-index">{index + 1}.</div>
                <h3 className="pnn">{product.name}</h3>
               
              </div>
            </div>
          ))}
        </div>

        {/* Right Side: Product Image */}
        <div className="product-image-container">
          <img
            src={displayedProduct.img} // Show hovered product or selected product
            alt={displayedProduct.name}
            className={`product-image11 ${isZooming ? 'zoom-out' : 'zoom-in'}`} // Apply zoom-in or zoom-out classes based on state
            onMouseEnter={() => setIsZooming(true)} // Trigger zoom-out on hover
            onMouseLeave={() => setIsZooming(false)} // Zoom back in when not hovering
          />
        </div>
      </div>
    </div>
  );
};

export default Brands;
