import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import "../About/CardDetails.css";

const CardDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cardData } = location.state || {};

  // Handle case when no card data is passed
  if (!cardData) {
    return (
      <div className="card-detail-aa">
        <h2 className="not-found-title-aa">Card Details Not Found</h2>
        <button className="back-button-aa" onClick={() => navigate('/about')}>Go Back</button>
      </div>
    );
  }

  const {
    title,
    image,
    content: {
      Heading,
      Description,
      Key_Benefits,
     
    }
  } = cardData;

  return (
    <div className="card-detail-aa">
    <div className="card-d-aa">
      <button className="back-button2-aa" onClick={() => navigate('/about')}>
        &larr; Back to About
      </button>
      <h2 className='title-cardd-aa'>{title}</h2>
      <img src={`/${image}`} alt={title} className="detail-image-aa" />
      
      <h3 className="section-heading-aa">{Heading}</h3>
      <ReactMarkdown className="description-text-aa">{Description}</ReactMarkdown>

      {Key_Benefits && Key_Benefits.length > 0 && (
  <>
    <h4 className="benefits-heading-aa">Key Benefits</h4>
    <ul className="benefits-list-aa">
      {Key_Benefits.map((benefit, index) => (
        <li key={index} className="benefit-item-aa">{benefit}</li>
      ))}
    </ul>
  </>
)}


      

     
      </div>
    </div>
  );
};

export default CardDetail;
