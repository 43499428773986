import React, { useState, useEffect } from "react";
import "../Shop/Shop.css";
import { IoLogoWhatsapp } from "react-icons/io";

import { CiFilter } from "react-icons/ci";
import { Link } from "react-router-dom";
import Icard from "../Icard/Icard";
import LazyImage from "../Lazyimage/Lazyimage";

const Shop = () => {
  const minPrice = 0;
  const maxPrice = 10000;
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedSkinMoodTypes, setSelectedSkinMoodTypes] = useState([]);
  const [priceRange, setPriceRange] = useState(maxPrice);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [isIcardOpen, setIsIcardOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Prevent background scrolling when sidebar is open on mobile
  useEffect(() => {
    if (!sidebarCollapsed && isMobile) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [sidebarCollapsed, isMobile]);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const closeSidebar = () => {
    setSidebarCollapsed(true);
  };


  
  const shopData = {
    filters: {
      productTypes: [
        { id: 1, name: "Cleansing" },
        { id: 2, name: "Serums" },
        { id: 3, name: "Moisturizer" },
        { id: 4, name: "Exfoliators" },
        { id: 5, name: "Mask" },
        { id: 6, name: "Eye" },
        { id: 7, name: "Spot Corrector" },
        { id: 8, name: "Face Oil" },
      ],
      skinMoodTypes: [
        { id: 1, name: "Open Pores" },
        { id: 2, name: "Melasma" },
        { id: 3, name: "Dry Skin" },
        { id: 4, name: "Acne Prone" },
        { id: 5, name: "Sensitive Skin" },
      ],
    },
    products: [
      {
        id: "pink-rose-cleansing-balm",
        image: "pinkt.avif",
        slider: ["pinkl.jpg", "pink3.jpeg", "pink6.jpeg", "pink5.jpeg"],
        title: "Pink Rose Cleansing Balm ",
        title2: "Pink Rose Cleansing Balm: Gentle Cleansing for Radiant Skin ",
        incre:
          "This cleansing balm is packed with skin-loving ingredients: | Sunflower oil: Moisturizes and softens skin | French Pink Clay: Gently exfoliates and purifies | Calamine: Soothes and calms irritated skin | Vitamin E: Protects skin from free radicals | Juniper Berry and Geranium Essential Oils: Provide a natural, refreshing scent ",
        description2:
          " Our Pink Rose Cleansing Balm offers a soothing and effective way to cleanse your skin. Made with natural ingredients like Sunflower oil and French Pink Clay, this balm gently removes makeup, dirt, and impurities while nourishing your skin. The rich, creamy texture melts into your skin, leaving it feeling soft, clean, and refreshed. ",
        description:
          " Discover our gentle Pink Rose Cleansing Balm, a 3-in-1 oil-based cleanser that transforms from balm to milk. Infused with Kaolin, Calamine, and French Pink clay for a natural skin detox. Experience clean, soft, and refreshed skin today.",
        price: 700,
        how: "It's a Balm to Milk Cleanser. It gently removes dirt, dust, and grime from the skin’s surface without stripping off natural oils. It keeps the skin nourished and moisturized. ",
        ingredients:
          " Sunflower oil , Stearic acid , French Pink Clay , Cetearyl Alcohol , Ceteareth 20 , Cetyl Alcohol , Polysorbate 80 , Castor oil , Calamine , Kaolin Clay , Vegetable Glycerin , D-Alpha Tocopherol Acetate(Vitamin E) , Geogard ECT(Preservative) , Juniper Berry E.O , Geranium E.O ",
        direction:
          " Apply a dime size of product on wet face and spread across face and neck as mask. You can keep this for 5 mins to have extra benefits of clay or can remove it with water. Use it as the 1st step in the AM/PM routine. Also use it while removing makeup. ",
        size: "45 gm",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 120,
        type: "Cleansing",
        skinMood: ["Dry Skin", "Melasma", "Sensitive Skin"],
        faq: [
          {
            question: "Is this product suitable for sensitive skin?",
            answer:
              "Yes, our Pink Rose Cleansing Balm is formulated with gentle ingredients suitable for sensitive skin. ",
          },
          {
            question: " Can it remove waterproof makeup? ",
            answer:
              "Yes, the balm effectively removes most types of makeup, including waterproof formulas. ",
          },
          {
            question: "Is this product cruelty-free? ",
            answer:
              "Yes, our Pink Rose Cleansing Balm is cruelty-free and not tested on animals. ",
          },
        ],
        ingredientsImages: [
          { name: "Sunflower oil", image: "sunfloweroil.jpg" },
          { name: "Calamine Clay", image: "calamine.jpg" },
          { name: "French Pink Clay", image: "frenchpinkclay.jpg" },
          { name: "Juniper Berry ", image: "juniperberry.jpg" },
          { name: "Kaolin clay ", image: "kaolinclay.jpg" },
          {
            name: "Geranium Essential Oil ",
            image: "geraniumessentialoil.jpg",
          },
        ],
      },
      {
        id: "green-tree-cleansing-balm",
        image: "gtcover.jpg",
        slider: ["gtl.jpg", "gtb.jpeg", "gtl2.jpg", "gt3.jpg"],
        title: "Green Tree Cleansing Balm",
        title2: "Green Tree Cleansing Balm: 3-in-1 Natural Skin Detox ",

        incre:
          " Enhanced with Beneficial Extracts : We've added some special ingredients to boost the effectiveness of our cleansing balm . Kojic Acid DPT is known for its skin-brightening properties, helping to even out skin tone . The essential oils of juniper berry and lavender not only provide a pleasant, natural scent but also offer additional skincare benefits. Juniper berry is known for its purifying properties, while lavender is calming and can help balance the skin ",
        description2:
          "It’s a blend of natural oils and clays that work together to cleanse your skin gently. Sunflower oil and castor oil help dissolve makeup and impurities, while French Green Clay and Kaolin Clay absorb excess oil and remove dirt from your pores. This balm effectively cleanses without stripping your skin of its natural moisture.",

        description:
          "Discover the power of nature with our Green Tree Cleansing Balm. This gentle, oil based cleanser combines clay and herbal infusions to detoxify, nourish, and revitalize your skin. Experience cleaner, softer skin today. ",
        how: "It's a Balm to Milk Cleanser. It gently removes dirt, dust, grime from the skin’s surface without stripping off natural oils | It keeps the skin nourished and moisturized | Sunflower oil and Castor oil: Cleansing oils that dissolve makeup and impurities | French Green Clay and Kaolin Clay: Absorb excess oil and remove dirt from pores | Vegetable Glycerin: Hydrates and moisturizes the skin | Frankincense Extract and Seaweed Extract: Soothe and calm the skin | Vitamin E (Alpha Tocopherol Acetate): Antioxidant that protects the skin | Kojic Acid DPT: Helps brighten and even skin tone | Juniper Berry and Lavender Essential Oils: Provide natural fragrance and additional skincare benefits  ",
        ingredients:
          " Sunflower oil , Stearic acid , French Green Clay , Cetearyl Alcohol , Ceteareth 20 , Cetyl Alcohol , Polysorbate 80 , Castor oil , Calamine , Kaolin Clay , Vegetable Glycerin , Frankincense Extract , seaweed Extract , Ascorbyl Palmitate , Kojic Acid DPT , Alpha Tocopherol Acetate(Vitamin E) , Geogard ECT(Preservative) , Juniper Berry E.O , Lavender E.O ",
        direction:
          "Apply a dime size of product on wet face and spread across face and neck as mask. You can keep this for 5 mins to have extra benefits of clay or can remove it with water . Use it as the 1st step in the AM/PM routine. Also use it while removing makeup. Caution: Avoid contact with eyes, if in contact rinse thoroughly with water. ",
        price: 700,
        size: "45 gm",
        emi: "No Cost EMI Available",
        rating: 4.9,
        reviews: 85,
        type: "Cleansing",
        skinMood: ["Open Pores", "Acne Prone"],
        faq: [
          {
            question: " Is this product suitable for all skin types? ",
            answer:
              "Yes, our Green Tree Cleansing Balm is formulated to be gentle and suitable for all skin type especially oily, combination including sensitive skin",
          },
          {
            question: "How do I use this cleansing balm? ",
            answer:
              "Scoop a small amount of the balm with a clean spatula and add little water. Gently massage it onto skin in circular motions. Rinse off with water or wipe away with a damp cloth. ",
          },
          {
            question: "Will this remove waterproof makeup?",
            answer:
              "Yes, the oils in this balm are effective at breaking down and removing even waterproof makeup. ",
          },
          {
            question: "Is this product cruelty-free? ",
            answer: " Yes, we do not test our products on animals",
          },
          {
            question: " How long will one jar last? ",
            answer:
              "With regular use, one jar typically lasts 2 months, depending on how much you use each time. ",
          },
        ],
        ingredientsImages: [
          { name: "French Green Clay", image: "frenchgreenclay.jpg" },
          { name: "Frankincense Extract", image: "frankincenseextract.jpg" },
          { name: "Kaolin Clay", image: "kaolinclay.jpg" },
          { name: "Calamine Clay", image: "calamine.jpg" },
          { name: "Castor Oil", image: "castoroil.jpg" },
          { name: "Kojic Acid DPT", image: "kojicaciddpt2.jpg" },
        ],
      },

      {
        id: "pure-cleanse-micellar-water",
        image: "purecover.jpg",
        slider: ["pure.jpeg", "pure1.jpeg", "pure3.jpg", "pure4.jpg"],
        title: "Pure Cleanse Micellar Water",
        title2: "Pure Cleanse Micellar Water: The Ultimate Skincare Cleanser",

        description:
          "Discover Pure Cleanse Micellar Water, a gentle yet effective cleanser enriched with natural extracts. This 4-in-1 solution cleanses, removes makeup, tones, and hydrates, suitable for all skin types.",
        description3:
          "Hydration and Skin Support:Experience the moisturizing power of our Micellar Water. Enriched with Licorice Extract and Allantoin, this formula helps to hydrate and calm the skin. Sodium Lactate acts as a natural moisturizing factor, helping to maintain your skin's hydration levels. The addition of Cocamidopropyl Betaine ensures a gentle cleansing action that won't strip your skin of its natural oils",
        price: 750,
        how: "It's a mild Cleanser which will remove all the dirt, dust and grime from your skin surface. It’s going to keep your skin soft and supple. Infused with a unique combination of natural extracts, our Micellar Water goes beyond simple cleansing. Rose Hydrosol and Clary Sage Hydrosol work together to soothe and balance the skin, while Ashwagandha extract helps protect against environmental stressors. Lemon Extract brightens the complexion, and Green Tea Extract provides antioxidant benefits to keep your skin looking youthful. ",
        ingredients:
          "Rose Hydrosol , Clary Sage Hydrosol , Ashwagandha Extract , Lemon Extract , Green Tea Extract , Licorice Extract , Cocamidopropyl Betaine , Sodium Lactate , Geogard ECT (Preservative) , Allantoin , Sodium Gluconate",
        direction:
          "Apply 2 pumps into cotton or palm and spread over the damp face and then rinse off with plain water. Apply single or after Cleansing Balm in your AM/PM routine. ",
        size: "100 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 85,
        type: "Cleansing",
        skinMood: [
          "Dry Skin",
          "Open Pores",
          "Acne Prone",
          "Melasma",
          "Sensitive Skin",
        ],
        faq: [
          {
            question:
              "Is Pure Cleanse Micellar Water suitable for all skin types? ",
            answer:
              "Yes, our gentle formula is designed for all skin types, including sensitive skin. ",
          },
          {
            question: "Do I need to rinse after using the micellar water?",
            answer:
              " Simply apply with a cotton pad and gently wipe your face or wash with water. ",
          },
          {
            question: " Can I use this product every day? ",
            answer:
              "Absolutely! Pure Cleanse Micellar Water is gentle enough for daily use, morning and night. ",
          },
        ],
        ingredientsImages: [
          { name: "Clary Sage Hydrosol", image: "clarysageessentialoil.png" },
          { name: "Ashwagandha", image: "ashwagandha.jpg" },
          { name: "Allantoin", image: "all.png" },
          {
            name: "Cocamidopropyl Betaine",
            image: "cocamidopropylbetaine.jpg",
          },
          { name: "Rose Hydrosol", image: "rosehydrosol2.jpg" },
          { name: "Licorice Extract", image: "licoriceextract2.jpg" },
        ],
      },
      {
        id: "natural-powder-facewash",
        image: "naturalcover.jpg",
        slider: ["natural.jpeg", "naturall.jpg", "naturall2.jpg"],
        title: "Natural Powder Facewash",
        title2: "Natural Powder Facewash: Cleanse and Nourish",

        description:
          "Experience the gentle cleansing power of our natural powder facewash. Made with Kaolin Clay, French Blue Clay, and soothing oatmeal, this face wash leaves your skin clean, refreshed, and nourished. This versatile formula combines Kaolin Clay and French Blue Clay to absorb excess oil and impurities, leaving your skin feeling fresh and clean. The addition of diatomaceous earth provides gentle exfoliation, helping to remove dead skin cells and reveal a brighter complexion. ",

        price: 199,
        incre:
          "Benefits for All Skin Types: | Oily Skin: Clays and arrowroot help control excess oil production | Dry Skin: Safflower oil and vitamin E provide essential moisture | Sensitive Skin: Colloidal oatmeal and allantoin soothe and calm irritation | Combination Skin: Balances oil production while hydrating dry areas | Acne-Prone Skin: Clays and licorice powder help reduce breakouts and inflammation ",
        how: " Our Natural Powder Facewash combines premium ingredients to cleanse and nourish your skin:| Sodium Cocoyl Isethionate: A mild, coconut-derived cleanser that gently removes dirt and oil  | French Blue Clay & Kaolin Clay: Absorb excess oil and impurities while adding minerals  | Diatomaceous Earth: Exfoliates and removes dead skin cells for a smoother complexion  | Arrowroot: Helps absorb excess oil and leaves skin feeling soft  | L-Ascorbic Acid (Vitamin C): Brightens skin and promotes collagen production | Colloidal Oatmeal: Soothes and calms sensitive or irritated skin  | Licorice Powder:  Helps even out skin tone and reduce dark spots  | Safflower Oil & Vitamin E: Moisturize and protect skin from free radicals  | French Lavender Essential Oil:  Provides a calming scent and potential antibacterial benefits  | Allantoin:  Promotes skin healing and soothes irritation ",
        ingredients:
          "Sodium Cocoyl Isethionate , French Blue Clay , Kaolin Clay , Diatomaceous Earth , Arrowroot , L-Ascorbic Acid , Colloidal Oatmeal , Licorice Powder , Safflower Oil , Vitamin E , French Lavender E.O , Allantoin",
        direction:
          "Wet your face with warm water | Pour a small amount of powder into your palm | Add a few drops of water to create a creamy paste | Gently massage the paste onto your face in circular motions | Rinse thoroughly with water and pat dry | For optimal results, use morning and night as part of your skincare routine",
        size: "15 gm",
        emi: "No Cost EMI Available",
        rating: 4.6,
        reviews: 150,
        type: ["Cleansing", "Exfoliators", "Mask"],

        skinMood: ["Open Pores", "Acne Prone"],
        faq: [
          {
            question: "Is this product suitable for sensitive skin?",
            answer:
              "Yes, our Natural Powder Facewash contains gentle ingredients like colloidal oatmeal and allantoin that are ideal for sensitive skin.",
          },
          {
            question: "How long does one container last?",
            answer:
              "With regular use, one container typically lasts 1-2 months depending on how often you use it.",
          },
          {
            question: "Can I use this product if I have acne?",
            answer:
              "Yes, the clays and licorice powder in our facewash help reduce breakouts and inflammation. For severe acne, consult a dermatologist.",
          },
          {
            question: "Is this product vegan and cruelty-free?",
            answer:
              "Yes, our Natural Powder Facewash is 100% vegan and we never test on animals.",
          },
          {
            question: "Can I use this product with other skincare products?",
            answer:
              "Yes, it can be integrated into your skincare routine. We recommend patch testing when introducing new products.",
          },
        ],
        ingredientsImages: [
          { name: "French Blue Clay", image: "frenchblueclay.jpg" },
          { name: "Safflower Oil", image: "sunfloweroil.png" },
          { name: "Colloidal Oatmeal", image: "colloidaloatmeal.jpg" },
          { name: " Kaolin Clay  ", image: "kaolinclay3.jpg" },
          {
            name: "French Lavender Essential Oil",
            image: "frenchlavenderessentialoil.jpg",
          },
        ],
      },
      {
        id: "oat-bloom-moisturizer",
        image: "bloomcover.jpg",
        slider: ["bloomn.jpg", "oat1.jpg", "oat2.jpg", "bloomn2.jpg"],
        title: "Oat Bloom Moisturizer",
        title2:
          "Oat Bloom Moisturizer: Soothing, Nourishing, Brightening Formula",
        incre: " ",

        description:
          "  Discover the power of oat protein and botanical ingredients in our Oat Bloom Moisturizer. It is a multifunctional skincare product designed to hydrate, brighten, and soothe the skin. It Protects skin from free radical damage and boosts collagen production for firmer skin.It soothes sensitive skin, nourishes with vitamins, and achieves a brighter complexion Perfect for daily use.",
        price: 1850,
        how: "It's a Hydrating and Nourishing Moisturizer with humectants .Tranexamic acid is a Collagen Guardian and Skin Barrier Fortifier| Hydrates & Nourishes: Maintains moisture balance, preventing dryness and flakiness. | Brightens & Evens Tone: Targets dark spots and uneven skin tone for a radiant complexion. | Soothes & Calms: Ideal for sensitive or irritated skin, reducing redness and inflammation | Protects Against Aging: Antioxidants from ingredients like blueberry and vitamin C protect the skin from environmental damage and promote a youthful appearance.  ",
        ingredients:
          "Frankincense Hydrosol , Caprylic Capric Triglyceride , Licorice Oil , Cetearyl Alcohol , Blueberry Extract , Niacinamide , Tranexamic Acid , Panthenol , Aquaxyl™ , Ascorbyl Palmitate , Oat Protein , Vitamin E , Geranium E.O , Blue Tansy E.O , Allantoin , Sodium Gluconate , Xanthan Gum",
        direction:
          "Apply a pea size amount on the face and neck. Apply AM/PM as Step-3 after Serum",
        size: "50 gm",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 120,
        type: "Moisturizer",
        skinMood: ["Dry Skin", "Melasma", "Sensitive Skin"],
        faq: [
          {
            question: "Is this moisturizer suitable for sensitive skin?",
            answer:
              "Yes, the Oat Bloom Moisturizer is formulated with gentle ingredients like oat protein and frankincense hydrosol, making it ideal for sensitive skin.",
          },
          {
            question: "How often should I use this moisturizer?",
            answer:
              "For best results, use the Oat Bloom Moisturizer twice daily, morning and night, as part of your skincare routine.",
          },
          {
            question: "Can this moisturizer help with uneven skin tone?",
            answer:
              "Yes, the combination of tranexamic acid, licorice oil, and Vitamin C helps address uneven skin tone and reduce dark spots.",
          },
          {
            question: "Is this product cruelty-free?",
            answer:
              "Yes, our Oat Bloom Moisturizer is cruelty-free and not tested on animals.",
          },
          {
            question: "How long does it take to see results?",
            answer:
              "Many users report seeing improvements in skin texture and hydration within 2-4 weeks of consistent use.",
          },
          {
            question: "Can I use this moisturizer under makeup?",
            answer:
              "Absolutely! Our non-greasy formula absorbs quickly, making it an excellent base for makeup.",
          },
          {
            question: "Does this moisturizer contain fragrances?",
            answer:
              "The moisturizer contains natural essential oils like geranium and blue tansy. We can formulate without essential oils upon request for sensitive skin.",
          },
        ],
        ingredientsImages: [
          { name: "Niacinamide", image: "niacinamide.jpg" },
          { name: "  Blue Tansy Essential Oil", image: "bluetansy.png" },
          { name: "Oat Protein", image: "oatprotein.png" },
          { name: "Tranexamic Acid", image: "tranexamicacid.jpg" },
        ],
      },
      {
        id: "blue-moon-moisturizer",
        image: "mooncover.jpg",
        slider: ["bluen.jpg", "bluel.jpg", "blue11.jpg", "blue3.jpg"],
        title: "Blue Moon Moisturizer",
        title2: "Blue Moon Moisturizer: Nature's Hydration Solution",
        incre: "",
        description2:
          "Our unique blend of Licorice & Blue Pea Butterfly Oil, along with Niacinamide and Tranexamic acid, works to even out skin tone and reduce the appearance of dark spots. The addition of Panthenol and Aquaxyl(™) helps to strengthen your skin's natural barrier, keeping it healthy and protected.",
        description:
          " Blue Moon Moisturizer combines the power of natural ingredients to deeply hydrate and rejuvenate your skin. Infused with Neroli Hydrosol and Blueberry Extract, this moisturizer provides a refreshing boost of moisture while helping to improve your skin's overall appearance.",
        price: 1850,
        how: "Deeply hydrates and moisturizes skin | Evens out skin tone and reduces dark spots | Strengthens skin's natural barrier | Soothes and calms irritated skin | Improves overall skin appearance ",
        ingredients:
          "Neroli Hydrosol , Caprylic Capric Triglyceride , Blue Pea Butterfly Oil , Licorice Oil , Polyglyceryl-2 Stearate , Glyceryl Stearate , Stearyl Alcohol , Blueberry Extract , Niacinamide , Tranexamic acid , Panthenol , Propanediol , Geogard ECT , Oat Protein , D-Alpha Tocopherol Acetate , Geranium E.O , Blue Tansy E.O , Allantoin , Sodium Gluconate",
        direction:
          "Apply a pea-size amount on the face and neck. Apply AM/PM as Step-3 after serum.",
        size: "50 gm",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 95,
        type: "Moisturizer",
        skinMood: ["Dry Skin", "Melasma", "Sensitive Skin"],
        faq: [
          {
            question: "Is Blue Moon Moisturizer suitable for all skin types?",
            answer:
              "Yes, it's formulated to be gentle and effective for all skin types.",
          },
          {
            question: "How often should I use this moisturizer?",
            answer: "For best results, use twice daily, morning and night.",
          },
          {
            question: "Is this product cruelty-free?",
            answer:
              "Yes, Blue Moon Moisturizer is cruelty-free and not tested on animals.",
          },
          {
            question: "Can I use this moisturizer under makeup?",
            answer:
              "Absolutely! It absorbs quickly and provides a smooth base for makeup application.",
          },
          {
            question: "Does this product contain any artificial fragrances?",
            answer:
              "No, the scent comes from natural essential oils like Geranium and Blue Tansy.",
          },
        ],
        ingredientsImages: [
          { name: "Neroli Hydrosol", image: "neroli.jpg" },
          { name: "Blueberry Extract", image: "blueberry.jpg" },
          { name: "D Panthenol", image: "dpanthenol.jpg" },
          { name: "Blue Pea Butterfly Oil", image: "Blue Pea1.jpg" },
          { name: " Aquaxyl(™) ", image: "aqua.png" },
        ],
      },
      {
        id: "azeloyl-skin-renewal-moisturizer",
        image: "azeloylcover.jpg",
        slider: ["azeloyl.jpg", "azeloyl3.jpg", "azeloyl2.jpg", "azeloyln.jpg"],
        title: "Azeloyl Skin Renewal Moisturizer",
        title2:
          "Azeloyl Skin Renewal Moisturizer:Renew Your Skin with Azeloyl Moisturizer",

        description2:
          "This moisturizer is specially formulated for acne-prone skin, using Potassium Azeloyl Diglycinate to help renew and refresh your complexion. The addition of Willow bark extract and Zinc PCA provides natural support for managing excess oil and promoting clearer skin. With regular use, you may notice a smoother, more even-toned appearance. ",
        description:
          "Experience the power of natural ingredients and advanced skincare technology with our Azeloyl Skin Renewal Moisturizer. Hydrate, brighten, and renew your skin for a radiant, youthful complexion.",
        price: 1850,
        how: "This moisturizer harmonizes the natural balance of the skin, promoting a healthy and radiant complexion. Active ingredients in this helps fight acne, inhibits melanin production, minimizes pores and helps fade spots. | Our Azeloyl Skin Renewal Moisturizer is a powerhouse of hydration and skin-loving ingredients. Formulated with a blend of Frankincense and Rose Hydrosols, this moisturizer provides deep hydration while soothing and calming the skin. The addition of Caprylic Capric Triglyceride and Rice Bran Oil helps to lock in moisture, leaving your skin feeling soft and supple. | Experience the benefits of cutting-edge skincare technology with our Azeloyl Skin Renewal Moisturizer. The star ingredient, Potassium Azeloyl Diglycinate , works to improve skin texture and tone. Combined with Niacinamide and Tranexamic acid, this moisturizer helps to even out skin tone and reduce the appearance of dark spots, giving you a more youthful and radiant complexion. | Our moisturizer is packed with natural ingredients that promote healthy skin. Willow bark extract provides gentle exfoliation, while Alpha Arbutin helps to brighten the skin. The addition of Zinc PCA helps to control excess oil production, making this moisturizer suitable for all skin types. With the antioxidant properties of Vitamin E (D-Alpha Tocopherol), your skin is protected from environmental stressors.  ",
        ingredients:
          "Frankincense Hydrosol , Rose Hydrosol , Caprylic Capric Triglyceride , Rice Bran Oil , Glycerin , Niacinamide , Tranexamic Acid , Potassium Azeloyl Diglycinate , Willow Bark Extract , Alpha Arbutin , Zinc PCA , Vitamin E , Allantoin , Sodium Gluconate , Xanthan Gum",
        direction:
          "Apply a pea size amount on the face and neck. Apply AM/PM as Step-3 after Serum Caution: Avoid contact with eyes ,if in contact rinse thoroughly with normal water. ",
        size: "50 gm",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 95,
        type: "Moisturizer",
        skinMood: ["Open Pores", "Acne Prone"],
        faq: [
          {
            question: "Is this moisturizer suitable for all skin types?",
            answer:
              " Yes, our azeloyl Skin Renewal Moisturizer is formulated to be gentle and effective for all skin type specially for oily, acne prone and combination skin or with dark spots.",
          },
          {
            question: "How often should I use this product?",
            answer:
              "For best results, apply twice daily, in the morning and evening, after cleansing your face.",
          },
          {
            question: "Can I use this moisturizer under makeup?",
            answer:
              "Absolutely! The lightweight formula absorbs quickly, making it an excellent base for makeup application.",
          },
          {
            question: "Is this product tested on animals?",
            answer:
              "No, our Azeloyl Skin Renewal Moisturizer is cruelty-free and not tested on animals.",
          },
          {
            question: "How long does it take to see results?",
            answer:
              "While individual results may vary, many users report seeing improvements in skin texture and tone within 4-6 weeks of consistent use.",
          },
        ],
        ingredientsImages: [
          { name: "Rose Hydrosol", image: "rosehydrosol.jpg" },
          { name: "Rice Bran Oil", image: "ricebrainoil.jpg" },
          { name: "Willow Bark Extract", image: "willowbarkextract.jpg" },
          { name: "Zinc PCA", image: "zincpca.png" },
          {
            name: "Potassium Azeloyl Diglycinate",
            image: "potassiumazeloyldiglycinate.png",
          },
        ],
      },
      {
        id: "cherry-berry-moisturizer",
        image: "cherrycover.jpg",
        slider: ["cherryn.jpg", "cherryn2.jpg", "cherry1.jpg", "cherryl.jpg"],
        title: "Cherry Berry Moisturizer",
        title2: "Cherry Berry Moisturizer: Nature's Nourishment for Your Skin",

        description:
          "Our Cherry Berry Moisturizer is formulated with Rose Hydrosol and Jojoba Oil, known for their excellent moisturizing properties. These ingredients work together to lock in moisture, leaving your skin feeling fresh and hydrated throughout the day. The addition of Grapeseed Oil, infused with hibiscus, further enhances the moisturizer's ability to keep your skin smooth and radiant. This unique blend combines the goodness of cherry and strawberry extracts to provide deep hydration and nourishment to your skin.",
        price: 1350,
        how: " It's a Hydrating and Nourishing Moisturizer with humectants and extracts.  | Experience the refreshing power of nature with our Cherry Berry Moisturizer. Packed with strawberry and cherry extracts, this lightweight formula delivers a burst of hydration to your skin. The natural fruit acids gently exfoliate, leaving your complexion smooth and radiant. | Our unique blend of jojoba and grapeseed oils, infused with hibiscus, creates a silky texture that absorbs quickly. These plant-based ingredients work together to soften and nourish your skin, without leaving a greasy residue. ",
        ingredients:
          "Rose Hydrosol , Jojoba Oil , Grapeseed Oil (Infused with Hibiscus) , Cetearyl Alcohol , Cetearyl Glucoside , Strawberry Extract , Sodium Lactate , Cherry Extract , Propanediol , Vitamin E (D-Alpha Tocopherol Acetate) , Lavender Essential Oil , Allantoin , Sodium Gluconate , Xanthan Gum",
        direction:
          "Apply a pea size amount on the face and neck and gently massage. ",
        size: "50 gm",
        emi: "No Cost EMI Available",
        rating: 4.8,
        reviews: 80,
        type: "Moisturizer",
        skinMood: [
          "Dry Skin",
          "Open Pores",
          "Acne Prone",
          "Melasma",
          "Sensitive Skin",
        ],
        faq: [
          {
            question: "Is this moisturizer suitable for all skin types?",
            answer:
              "Yes, our Cherry Berry Moisturizer is formulated to be gentle and effective for all skin types.",
          },
          {
            question: "How often should I use this product?",
            answer:
              "For best results, apply the moisturizer twice daily, in the morning and evening, after cleansing your face.",
          },
          {
            question: "Does this product contain any artificial fragrances?",
            answer:
              "No, the pleasant scent comes from natural ingredients like lavender essential oil and rose hydrosol.",
          },
          {
            question: "Is this moisturizer cruelty-free?",
            answer: "Yes, we do not test our products on animals.",
          },
          {
            question: "Can I use this moisturizer under makeup?",
            answer:
              "Absolutely! The lightweight formula absorbs quickly, making it an excellent base for makeup application.",
          },
        ],
        ingredientsImages: [
          { name: "Jojoba Oil", image: "jojobaoil.jpg" },
          { name: "Grapeseed Oil", image: "grapeseedoil.jpg" },
          { name: "Cherry Extracts", image: "cherryextracts.jpg" },
          { name: "Sodium Lactate", image: "sodiumlactate.png" },
        ],
      },
      {
        id: "papaya-scrub-with-papain",
        image: "papayacover.jpg",
        slider: ["papaya2.jpeg", "papayal.png", "papaya1.jpeg", "papaya3.jpg"],
        title: "Papaya Scrub with Papain",
        title2: "Papaya Scrub with Papain: Your Skin's New Best Friend",
        incre:
          "Nourishing Ingredients for Soft, Supple Skin:Our formula is packed with nourishing elements that work together to improve your skin's texture and appearance |Shea Butter: Deeply moisturizes and softens skin | Grapeseed Oil: Lightweight and easily absorbed, it helps maintain skin's elasticity | Vitamin E: A powerful antioxidant that protects skin from environmental stressors | Glycerin: Attracts moisture to keep skin hydrated",
        description3:
          "Tropical Exfoliation (The Power of Papaya):Experience the gentle yet effective exfoliation of our Papaya Scrub with Papain. Derived from the papaya fruit, papain is a natural enzyme that helps remove dead skin cells, revealing a brighter, smoother complexion. This tropical-inspired scrub combines the benefits of papaya with other skin-loving ingredients to give you a spa-like experience at home. ",
        description:
          "Experience gentle exfoliation and deep hydration with our natural papain facial scrub. This facial scrub provides deep hydration while cleansing. The combination of xylitol and papaya hydrosol enzymes helps maintain skin moisture balance. ",
        price: 650,
        price2: 950,
        how: "It's an exfoliation product meant for a weekly regime. This removes the dead skin from the skin's surface keeping the skin hydrated and nourished. Follow the Treatment Mask after using this.",
        ingredients:
          "Xylitol , Papaya Hydrosol , Shea Butter , Coco Glucoside , Grapeseed oil(Infused with Papaya seeds)Coco Betaine , Glycerin , Cetyl Alcohol , Cetostearyl alcohol , Cetearth 20 , D-Alpha Tocopherol Acetate(Vitamin E) , Papain , Geogard ECT(Preservative) Clary Sage E.O , Juniper Berry E.O. ",
        direction:
          "Apply a big dime size on your face and neck. Massage gently with upwards direction for 5 -7 mins and leave on for another 5 mins. Then remove with plain water. It is to be applied weekly. ",
        size: "30 gm",
        size2: "50 gm",
        emi: "No Cost EMI Available",
        rating: 4.7,
        reviews: 65,
        type: "Exfoliators",
        skinMood: [
          "Dry Skin",
          "Open Pores",
          "Acne Prone",
          "Melasma",
          "Sensitive Skin",
        ],
        faq: [
          {
            question: "How often should I use the Papaya Scrub?",
            answer:
              "We recommend using the scrub 1-2 times per week for best results. Those with sensitive skin may want to start with once a week and adjust as needed. ",
          },
          {
            question: "Is this product suitable for all skin types?",
            answer:
              "Yes, our Papaya Scrub is formulated to be gentle enough for all skin types. However, if you have very sensitive skin or any skin conditions, we recommend doing a patch test first. ",
          },
          {
            question: "How long does the product last once opened?",
            answer:
              "When stored properly in a cool, dry place, the Papaya Scrub should last for 6 months after opening. Look for the symbol on the packaging indicating the period-after-opening (PAO) for specific guidance. ",
          },
        ],
        ingredientsImages: [
          { name: "Xylitol", image: "xylitol.jpg" },
          { name: "Shea Butter", image: "sheabutter.jpg" },
          { name: "Coco Glucoside", image: "cocoglucoside.jpg" },
          { name: "Papain", image: "papain.png" },
          { name: "Juniper Berry", image: "juniperberry.png" },
          {
            name: "Clary Sage Essential Oil ",
            image: "clarysageessentialoil.png",
          },
        ],
      },
      {
        id: "aha+bha+pha-facial-scrub",
        image: "ahacover.jpg",
        slider: [
          "abp0.jpg",
          "abp00.jpg",
          "abp.jpeg",
          "abp3.jpeg",
          "abp1.jpeg",
          "abp2.jpeg",
        ],

        title: "AHA+BHA+PHA Facial Scrub",
        title2: "Reveal Your Best Skin with Gentle Exfoliators",
        incre:
          "AHA (Mandelic Acid): Helps improve skin texture and tone | BHA (Salicylic Acid): Penetrates pores to remove excess oil and reduce acne | PHA (Gluconolactone): Provides gentle exfoliation suitable for sensitive skin Nourishing Natural Ingredients | This facial scrub is enriched with natural ingredients that nourish and protect your skin: | Grapeseed Oil: Rich in antioxidants to fight free radicals | Licorice Extract: Helps brighten and even out skin tone | Vitamin E: Provides antioxidant protection and moisturization | Essential Oils: Clary Sage and Frankincense for a soothing aroma ",

        description:
          "Our AHA, BHA, PHA Facial Scrub combines three powerful exfoliating agents to reveal smoother, brighter skin. The blend of alpha- hydroxy acids (AHA), beta- hydroxy acid (BHA), and poly- hydroxy acid (PHA) work together to remove dead skin cells, unclog pores, and promote cell turnover",
        price: 800,
        price2: 1300,
        how: "It combines the elements of purity, renewal, and the active ingredients, BHA and PHA, for a refreshed and rejuvenated complexion It combines the exfoliating power of BHA and PHA to cleanse and reveal a radiant glow. ",
        ingredients:
          "Aqua , Propanediol , Cetearyl Alcohol , Cetearyl Glucoside , Caprylic Capric Triglyceride , Coco Glucoside , Grapeseed Oil (Licorice Infused) , Caprylyl/Capryl Glucoside , Sodium Cocoyl Glutamate , Glyceryl Caprylate , Citric Acid , Polyglyceryl-6 Oleate , Sodium Surfactin , Mandelic Acid , Salicylic Acid , Gluconolactone , Vegetable Glycerin , Cetyl Alcohol , D-Alpha Tocopherol Acetate (Vitamin E) , Clary Sage E.O , Frankincense E.O , Sodium Gluconate , Allantoin , Xanthan Gum.",
        direction:
          " Apply a dime size of product on wet face and spread across face and neck with little massage. You can keep this for 5 -10 mins to have extra benefits and then remove with water",
        size: "30 gm",
        size2: "50 gm",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 72,
        type: "Exfoliators",
        skinMood: ["Dry Skin", "Open Pores", "Acne Prone", "Melasma"],
        faq: [
          {
            question: "How often should I use this facial scrub?",
            answer:
              "We recommend using the scrub 1-2 times per week, depending on your skin's sensitivity.",
          },
          {
            question: "Is this product suitable for sensitive skin?",
            answer:
              "Yes, the balanced formula is designed to be gentle on all skin types, including sensitive skin. However, we always recommend doing a patch test first.",
          },
          {
            question: "Can I use this scrub if I have acne-prone skin?",
            answer:
              "Absolutely. The BHA (salicylic acid) in the formula can help unclog pores and reduce acne breakouts.",
          },
          {
            question: "Will this scrub help with uneven skin tone?",
            answer:
              "Yes, regular use can help improve skin tone and texture by promoting cell turnover and removing dead skin cells.",
          },
          {
            question: "Is this product cruelty-free?",
            answer:
              "Yes, our facial scrub is cruelty-free and not tested on animals.",
          },
        ],
        ingredientsImages: [
          { name: "Clary sage Essential Oil", image: "claysage.jpg" },
          { name: " Salicylic Acid ", image: "salsilic.png" },
          { name: " Frankincense Essential Oil", image: "frankincense.jpg" },
          { name: " Mandelic Acid", image: "mendelic.png" },
          { name: "Gluconolactone ", image: "gluconolactone.png" },
          { name: "Coco Glucoside", image: "cocoglucoside.png" },
        ],
      },
      {
        id: "chocolate-clay-mask",
        image: "chocolatecover.jpg",
        slider: ["cc.jpeg", "cc1.jpg", "cc2.jpg", "cc3.jpg"],
        title: "Chocolate Clay Mask",
        title2:
          "Chocolate Clay Mask: Indulge Your Skin in Delicious Skincare Treat ",
        incre:
          "Nourishing Chocolate Goodness: Treat your skin to the rich, indulgent benefits of our Chocolate Clay Mask. This luxurious blend combines the power of chocolate clay with calamine and kaolin clays to deeply cleanse and rejuvenate your complexion. The natural antioxidants in chocolate help protect your skin from environmental stressors while providing a delightful, spa-like experience at home. | Hydrating and Soothing Formula : Our mask is infused with a blend of hydrating ingredients to keep your skin soft and supple. Rose hydrosol provides gentle toning, while sunflower oil and shea butter offer deep moisture. The addition of allantoin helps soothe and calm irritated skin, making this mask perfect for all skin types, even sensitive ones. | Brightening and Spot-Reducing Properties : Experience a more radiant complexion with our carefully selected brightening ingredients. Magnesium ascorbyl phosphate and ascorbyl palmitate, both forms of vitamin C, work together to improve skin tone and reduce the appearance of dark spots. Kojic acid DPT further enhances the brightening effect, helping you achieve a more even-toned and glowing complexion",

        description:
          "The combination of Chocolate Clay, Calamine Clay and Kaolin Clay absorbs excess oil, draws out impurities, and detoxifies the skin, leaving it refreshed and clear. It includes Magnesium Ascorbyl Phosphate, a stable form of vitamin C. This powerful antioxidant helps brighten skin tone, reduce the appearance of dark spots and support collagen production.",
        price: 750,
        price2: 1250,
        how: " It’s a Treatment Face Mask, to be used after face scrub every week or as per consultation. It tightens up your pores, hydrates and gives your skin a natural glow by targeting the spots.",
        ingredients:
          "Rose Hydrosol , Sunflower Oil , Shea Butter , Cetostearyl Alcohol , Cetearth 20 , Calamine Clay , Kaolin Clay , Coconut Butter , Chocolate Clay , Cetyl Alcohol , Spot Reduction Blend , Vegetable Glycerin , Magnesium Ascorbyl Phosphate , Ascorbyl Palmitate , Kojic Acid DPT , D-Alpha Tocopherol Acetate (Vitamin E) , Geogard ECT (Preservative) , Juniper Berry E.O , Lavender E.O , Sodium Gluconate , Allantoin.",
        direction:
          "Apply a thick layer on the face and neck after removing scrub or face oil. Spread across face and neck. You can keep this for 15-25 mins and remove gently with water. It is to be applied weekly. ",
        size: "30 gm",
        size2: "50 gm",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 85,
        type: "Mask",
        skinMood: [
          "Dry Skin",
          "Open Pores",
          "Acne Prone",
          "Melasma",
          "Sensitive Skin",
        ],
        faq: [
          {
            question: "How often should I use the Chocolate Clay Mask?",
            answer:
              "We recommend using the mask 1-2 times per week for best results.",
          },
          {
            question: "Is this mask suitable for all skin types?",
            answer:
              "Yes, our gentle formula is suitable for all skin types, including sensitive skin.",
          },
          {
            question: "How long should I leave the mask on?",
            answer:
              "Apply a thin layer to clean skin and leave it on for 15-20 minutes before rinsing with water.",
          },
          {
            question: "Can I use this mask if I have acne-prone skin?",
            answer:
              "Yes, the clay ingredients help absorb excess oil and will be beneficial for acne-prone skin.",
          },
        ],
        ingredientsImages: [
          { name: "Chocolate Clay", image: "chocolateclay.jpg" },
          { name: "Kaolin Clay", image: "kaolinclay.jpg" },
          { name: "Lavender Essential Oil", image: "lavender.jpg" },
          { name: "Coconut Butter", image: "coconutbutter.jpg" },
          { name: "Calamine Clay", image: "calamineclay.png" },
        ],
      },
      {
        id: "sparkle-eyes-revitalizing-eye-cream",
        image: "sparklecover.jpg",
        slider: [
          "sparklen.jpg",
          "sparklen2.jpg",
          "sparkle.jpeg",
          "sparklen3.jpg",
          "sparklel.jpg",
          "sparkle2.jpg",
        ],
        title: "Sparkle Eyes Revitalizing Eye Cream",
        title2: "Advanced Formula for Delicate Eye Skin",
        incre:
          "Gentle Nourishment for Delicate Skin : Our Sparkle Eyes Revitalizing Eye Cream is specially formulated to care for the sensitive skin around your eyes. With a blend of natural ingredients like Rose Hydrosol and Licorice Root Extract, this cream provides gentle nourishment without irritation. The lightweight texture absorbs quickly, leaving your skin feeling smooth and refreshed. | Powerful Hydration and Anti-Aging Benefits : Experience deep hydration with our unique combination of Hyaluronic Acid and Aquaxyl (TM). These ingredients work together to lock in moisture and improve skin elasticity. The addition of CoQ10 and Retinyl Palmitate helps fight signs of aging by promoting collagen production and reducing the appearance of fine lines and wrinkles.| Brightening and De-Puffing Properties : Say goodbye to dark circles and puffy eyes with our targeted ingredients. Kojic DPT and Alpha arbutin work to even out skin tone and reduce discoloration, while Coffee CO2 extract helps improve circulation and reduce puffiness. The result is a brighter, more awake-looking eye area. ",

        description:
          "Nourish and brighten your delicate eye area with Sparkle Eyes Revitalizing Eye Cream. Formulated with natural ingredients to hydrate, reduce dark circles, and combat signs of aging. Suitable for all skin types.",
        price: 1800,
        how: " It helps to repair, rejuvenate and hydrate the skin in a youthful and radiant appearance.",
        ingredients:
          "Rose Hydrosol , Hyaluronic Acid , Capric Caprylic Triglyceride , Licorice Root Extract , Aquaxyl™ , Tranexamic Acid , Alpha Glucosyl Hesperidin , CoQ10 , Niacinamide , Panthenol , Polyglyceryl-2 Stearate , Glyceryl Stearate , Stearyl Alcohol , Kojic DPT , Alpha Arbutin , Retinyl Palmitate , Coffee CO2 Extract , Allantoin , Sodium Gluconate.",
        direction:
          "Apply small pea size on your ring finger and gently dab in your skin in clockwise motion. Apply after serum in the night during your PM routine.",
        size: "15 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 90,
        type: "Eye",
        skinMood: "Sensitive Ski",
        faq: [
          {
            question: "How often should I use this eye cream?",
            answer:
              "For best results, apply the cream twice daily, in the morning and evening, after cleansing your face.",
          },
          {
            question: "Is this product suitable for sensitive skin?",
            answer:
              "Yes, our Sparkle Eyes Revitalizing Eye Cream is formulated with gentle ingredients suitable for most skin types, including sensitive skin.",
          },
          {
            question: "How long does it take to see results?",
            answer:
              "Many users report seeing improvements in skin hydration and texture within 4-6 weeks of regular use.",
          },
          {
            question: "Does this cream contain any fragrances?",
            answer:
              "Our eye cream is formulated without added fragrances to minimize the risk of irritation.",
          },
        ],
        ingredientsImages: [
          { name: "Coffee CO2", image: "coffeeco2.jpg" },
          {
            name: "Alpha Glucosyl Hespiridin ",
            image: "alphaglucosylhespiridin.png",
          },
          { name: "Alpha Arbutin", image: "alphaarbutin.png" },
          { name: "Kojic Acid DPT", image: "kojicaciddpt.png" },
          { name: "Hyaluronic Acid", image: "hyaluronicacid.png" },
          { name: "Tranexamic Acid ", image: "tranexamicacid.png" },
          { name: "COQ 10", image: "coq10.png" },
        ],
      },
      {
        id: "magnesium-ascorbyl-phosphate(map)+tranexamic-face-serum",
        image: "mapcover.jpg",
        slider: ["mapn.jpg", "mapn2.jpg", "map5.jpg", "map2.jpeg", "map4.jpg"],
        title: "Magnesium Ascorbyl Phosphate(MAP) + Tranexamic Face Serum",
        title2: "MAP+ Tranexamic Face Serum: Your Path to Radiant Skin",
        incre:
          "Deep Hydration for Plump, Smooth Skin :Experience intense hydration with our carefully selected ingredients. Hyaluronic Acid and Aquaxyl™ work in tandem to draw moisture into the skin, plumping fine lines and leaving your skin feeling soft and supple. Rose Hydrosol adds a soothing touch, while Propanediol helps to improve the absorption of active ingredients | Gentle Yet Effective Skin Care :This serum is formulated with sensitive skin in mind. Licorice Extract and Allantoin provide calming benefits, helping to soothe irritation and redness. Salicylic Acid gently exfoliates, promoting a smoother skin texture. The inclusion of Glycerin and Sorbic Acid helps to maintain the skin's natural moisture barrier, ensuring long-lasting hydration. ",

        description:
          "Discover our gentle yet effective face serum combining stable Vitamin C Magnesium Ascorbyl Phosphate (MAP), Tranexamic Acid, and Niacinamide. Address dark spots, uneven tone, and support collagen for brighter, healthier-looking skin.",

        price: 1350,
        how: "It's a Hydrating Serum formulated with a blend of ingredients that attracts and retains moisture,which will strengthen your skin barrier targeting age spots.  Our MAP+ Tranexamic Face Serum combines the potent brightening effects of Magnesium Ascorbyl Phosphate (MAP) and Tranexamic Acid. This dynamic duo works together to reduce the appearance of dark spots and uneven skin tone, giving you a more radiant complexion. The addition of Niacinamide further enhances the brightening effects, helping to improve overall skin clarity. ",
        ingredients:
          "Rose Hydrosol , Hyaluronic Acid Solution (1%) , Magnesium Ascorbyl Phosphate (MAP) , Propanediol , Tranexamic Acid , Niacinamide , Aquaxyl™ , Licorice Extract , Sepimax Zen , Benzyl Alcohol , Salicylic Acid , Glycerin , Sorbic Acid , Allantoin , Sodium Gluconate.",
        direction:
          "Apply a few drops of the serum on cleansed skin, gently massage until absorbed. Use twice daily for best results.",
        size: "30 ml",
        emi: "EMI options available",
        rating: 4.8,
        reviews: 65,
        type: "Serums",
        skinMood: [
          "Dry Skin",
          "Open Pores",
          "Acne Prone",
          "Melasma",
          "Sensitive Skin",
        ],
        faq: [
          {
            question: "How often should I use this serum? ",
            answer: "Apply twice daily, morning and night, for best results. ",
          },
          {
            question: "Is this serum suitable for all skin types?",
            answer:
              "Yes, it's formulated to be gentle enough for all skin types, including sensitive skin.",
          },
          {
            question: "How long before I see results?",
            answer:
              "Most users notice improvements in skin brightness and texture within 4-6 weeks of consistent use. ",
          },
          {
            question: "Can I use this serum with other skincare products? ",
            answer:
              "Yes, it can be easily incorporated into your existing skincare routine. ",
          },
          {
            question: "Is this product cruelty-free?",
            answer: "Yes, we do not test on animals. ",
          },
        ],
        ingredientsImages: [
          { name: "Tranexamic Acid", image: "tranexamicacid.jpg" },
          {
            name: "Magnesium Ascorbyl Phosphate (Stable Vitamin C)",
            image: "magni.jpg",
          },
          { name: "Aquaxyl™", image: "aquaxyl.jpg" },
          { name: "Licorice Extract", image: "licoriceextract.jpg" },
          { name: "Hyaluronic Acid", image: "hyaluronic acid.jpg" },
        ],
      },
      {
        id: "mandelic-acid+salicylic-acid-face-serum",
        image: "mandeliccover.jpg",
        slider: ["mandelicn.jpg", "mendlic2.jpg", "mandelicn2.jpg"],
        title: "Mandelic Acid + Salicylic Acid Face Serum",
        title2: "Mandelic & Salicylic Serum for Clearer Skin",
        incre:
          "Gentle Exfoliation for Glowing Skin:This powerful serum combines mandelic and salicylic acids to provide gentle yet effective exfoliation. Mandelic acid, derived from almonds, works to smooth skin texture and reduce the appearance of fine lines. Salicylic acid, a beta-hydroxy acid, penetrates pores to clear out excess oil and debris. Together, these acids help reveal brighter, clearer skin without harsh irritation. |  Targeted Treatment for Problem Areas:The spot corrector blend in this serum makes it especially effective for targeting areas of hyperpigmentation or acne. Whether you're dealing with dark spots, uneven skin tone, or breakouts, this serum can help. Regular use may lead to a more uniform complexion and reduced appearance of blemishes. ",

        description:
          "This face serum combines the power of mandelic acid (AHA) and salicylic acid (BHA) to gently exfoliate your skin. These ingredients work together to unclog pores, remove dead skin cells, and promote a smoother complexion. If you're dealing with acne-prone skin or stubborn blemishes, this serum can help reduce breakouts and improve overall skin texture.",
        price: 1350,
        how: "This AHA/BHA blend targets hyperpigmentation, unclogs pores, and promotes a clearer, more youthful skin.",
        ingredients:
          "Frankincense Hydrosol , Propanediol , Mandelic acid , Glycerin , Licorice Root Extract , Spot Corrector Blend , Salicylic Acid , Heptyl Glucoside , Benzyl Alcohol , Polyacrylate Crosspolymer-6 , Allantoin , Sodium Gluconate , Acacia Senegal Gum & Xanthan Gum.",
        direction:
          "Use 1-2 pumps covering face and neck preferably at night with 3C Serum or alone or as per consultation.",
        size: "30 ml",
        emi: "EMI options available",
        rating: 5,
        reviews: 72,
        type: "Serums",
        skinMood: ["Open Pores", "Acne Prone"],
        faq: [
          {
            question: "How often should I use this serum?",
            answer:
              "Start by using it 2-3 times per week, gradually increasing to daily use as your skin adjusts.",
          },
          {
            question: "Is this serum suitable for sensitive skin?",
            answer:
              "While generally well-tolerated, those with sensitive skin should patch test first and start with less frequent use.",
          },

          {
            question: "Can I use this serum with other skincare products?",
            answer:
              "Yes, but avoid using it with other exfoliating products on the same day to prevent over-exfoliation.",
          },
        ],
        ingredientsImages: [
          { name: "Mandelic Acid", image: "mandelicacid.jpg" },
          { name: "Salicylic Acid", image: "salicylicacid.jpg" },
          { name: "Licorice Extract", image: "licoriceextract.jpg" },
          { name: "Rose Hydrosol", image: "rose.png" },
        ],
      },
      {
        id: "clarity-complex-corrector",
        image: "claritycover.jpg",
        slider: ["clarityn.jpg", "clarity2.jpg", "clarity1.jpg"],
        title: "Clarity Complex Corrector",
        title2: "Advanced Skincare Science for Visible Results",
        incre:
          "Advanced Formula for Melasma, Dark spots:We understand the importance of caring for sensitive skin. That's why our Clarity Complex Corrector is formulated with gentle ingredients like Allantoin and Caesalpinia Spinosa Gum. These components work to soothe and protect your skin while still providing effective results. ",
        description:
          "Our Clarity Complex Corrector uses cutting-edge skincare technology to deliver real results. The combination of Polyglyceryl-3 Dicitrate/Stearate and Sodium Palmitoyl Proline-Nymphaea Alba Flower Extract helps to smooth dark spots and even out skin tone. Experience the difference that scientific innovation can make for your skin.",

        price: 1450,
        price2: 2250,
        how: " It has Lipoamino acids which works as Tyrosinase inhibitors that helps to clarify, balance the skin tone and makes the skin look more bright and radiant . ",
        ingredients:
          "Frankincense Hydrosol , Capric Caprylic Triglyceride , Glycerin , Polyglyceryl-3 Dicitrate/Stearate , Sodium Palmitoyl Proline-Nymphaea Alba Flower Extract , Undecylenoyl Phenylalanine , Benzyl Alcohol , Salicylic Acid , Sorbic Acid , Polyacrylate Crosspolymer-6 , Allantoin , Sodium Gluconate , Caesalpinia Spinosa Gum",
        direction:
          "Apply a small pea size on your finger and gently dab into your skin in a tapping motion. Apply after your regular serum or as a second step before moisturizer during AM/PM routine.",
        size: "15 ml",
        size2: "30 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 85,
        type: "Spot Corrector",
        skinMood: ["Open Pores", "Acne Prone", "Melasma", "Sensitive Skin"],
        faq: [
          {
            question: "How often should I use Clarity Complex Corrector?",
            answer: "For best results, use twice daily, morning and evening.",
          },
          {
            question: "Is this product suitable for all skin types?",
            answer:
              "Yes, our gentle formula is designed to work with all skin types.",
          },
          {
            question: "Can I use this product with other skincare items?",
            answer:
              "Absolutely! It integrates well into most skincare routines. ",
          },
          {
            question: "How long before I see results?",
            answer:
              "All skin reflect improvements in a different time period depending on intensity of hyperpigmentation but generally improvements are seen within 12-16 weeks of consistent use. ",
          },
          {
            question: "Is this product cruelty-free?",
            answer: "Yes, we never test on animals.",
          },
        ],
        ingredientsImages: [
          { name: "Nymphaea Alba Flower", image: "nymphaeaalbaflower.jpg" },
          {
            name: "Capric Caprylic Triglyceride",
            image: "capriccaprylic triglyceride.jpg",
          },
          { name: "Sepiwhite MSH", image: "sepiwhite.jpg" },
        ],
      },
      {
        id: "night-bliss-restorative-cream",
        image: "blisscover.jpg",
        slider: ["bliss1.jpeg", "bliss.jpeg", "bliss3.jpg"],
        title: "Night Bliss Restorative Cream",
        title2: "The Ultimate Skin Recovery",
        how: "Luxurious Nourishment for Radiant Skin: Our Night Bliss Restorative Cream combines nature's finest ingredients to rejuvenate your skin while you sleep. Enriched with rose hydrosol and mango butter, this cream deeply moisturizes and softens your skin, leaving it feeling supple and refreshed come morning.The addition of coenzyme Q10 and niacinamide helps to reduce the appearance of fine lines and wrinkles, promoting a more youthful complexion. These powerful antioxidants work together to protect your skin from environmental stressors and support its natural renewal process.  | Advanced Brightening and Even-Toning: Experience the brightening benefits of our carefully selected ingredients. Kojic acid dipalmitate and alpha arbutin work in harmony to gently fade dark spots and even out skin tone. The infusion of licorice grapeseed oil and saffron extract further enhances the cream's ability to promote a more uniform and radiant complexion.  |Skin - Barrier Strengthening Formula: The Night Bliss Restorative Cream is formulated with skin-barrier supporting ingredients to help fortify your skin's natural defenses. Ceramides play a crucial role in maintaining skin hydration and protecting against environmental aggressors. The addition of rosehip oil, rich in essential fatty acids, helps to improve skin elasticity and promote overall skin health. Panthenol and allantoin work together to soothe and calm the skin, making this cream suitable for all skin types, including sensitive skin. ",

        description:
          "Our Night Bliss Restorative Cream combines nature's finest ingredients to rejuvenate your skin while you sleep.It is designed to address hyperpigmentation concerns, helping you achieve a clearer and more luminous skin tone over time.",
        price: 2250,
        price2: 1650,

        ingredients:
          "Rose Hydrosol , Mango Butter , Coenzyme Q10 , Niacinamide , Polyglyceryl-2-Stearate , Glyceryl Stearate , Stearyl Alcohol , Licorice Infused Grapeseed Oil , Saffron Extract , Water , Pentylene Glycol , Phospholipids , Sphingolipids , Rosehip Oil , Propanediol , Panthenol , Vegetable Glycerin , Glyceryl Mono Oleate , Cetyl Alcohol , Kojic Acid Dipalmitate , Alpha Arbutin , Benzyl Alcohol , Salicylic Acid , Glycerin , Sorbic Acid , D-Alpha Tocopherol Acetate (Vitamin E) , Lavender Essential Oil , Frankincense Essential Oil , Sodium Gluconate , Allantoin , Xanthan Gum",
        direction:
          "Apply a dime size of product on your face after Serum or after your moisturizer and spread across face and neck with little massage. Keep this overnight and apply Face Oil to seal the moisture and hydration from this Night Repair Cream .",
        size: "50 gm",
        size2: "30 gm",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 90,
        type: "Moisturizer",
        skinMood: ["Dry Skin", "Melasma", "Sensitive Skin"],
        faq: [
          {
            question:
              "How often should I use the Night Bliss Restorative Cream?",
            answer:
              "For best results, apply the cream every evening after cleansing your face.",
          },
          {
            question: "Is this cream suitable for all skin types?",
            answer:
              "Yes, the Night Bliss Restorative Cream is formulated to be gentle and effective for all skin types.",
          },
          {
            question: "How long does it take to see results?",
            answer:
              "While individual results may vary, many users report seeing improvements in skin texture and tone within 4-6 weeks of consistent use.",
          },
          {
            question: "Does this cream contain any fragrances?",
            answer:
              " The cream contains natural essential oils of lavender and frankincense for a subtle, calming scent. ",
          },
          {
            question: "Can I use this cream during the day?",
            answer:
              "While designed for nighttime use, you can use the cream during the day if desired. However, be sure to apply sunscreen over it for daytime protection.",
          },
        ],
        ingredientsImages: [
          { name: "Mango Butter", image: "mangobutter.jpg" },
          { name: "Rosehip Oil", image: "rosehipoil.jpg" },
          { name: "Kojic Acid DPT", image: "kojicaciddpt.jpg" },
          { name: "Ceramides", image: "ceramides.jpg" },
          { name: "Alpha Arbutin", image: "alphaarbutin.jpg" },
          { name: "COQ10", image: "coq10.jpg" },
        ],
      },

      {
        id: "midnight-nourishment-blend",
        image: "midnightcover.jpg",
        slider: [
          "midnight2.jpg",
          "midnight3.jpg",
          "midnight.jpeg",
          "midnightl.jpg",
        ],
        title: "Midnight Nourishment Blend",
        title2:
          "Your Skin's Nighttime Remedy:Nutrient-Rich Botanical Oils for Deep Hydration ",

        description:
          "Our Midnight Nourishment Blend combines a powerful mix of botanical oils to provide deep hydration while you sleep. Argan oil, known for its moisturizing properties, works alongside rosehip and hemp seed oils to nourish your skin. These natural ingredients help improve skin texture and maintain a healthy moisture barrier.",

        price: 1450,
        how: "Gentle Spot Correction and Anti-Aging Benefits.This blend includes a special spot correction with Squalane and Bakuchiol, a natural alternative to retinol. These ingredients work together to help reduce the appearance of dark spots and fine lines. The addition of borage seed oil and wheatgerm oil provides extra support for skin elasticity and overall skin health.",
        ingredients:
          "Argan Oil , Bluepea Butterfly Oil (Infused) , Licorice Oil (Infused) , Hibiscus Oil (Infused) , Papaya Seed Oil (Infused) , Bakuchiol (Oil) , Squalane , Rosehip Oil , Hemp Seed Oil , Borage Seed Oil , Wheatgerm Oil , Spot Correction Blend , Clary Sage Essential Oil , Frankincense Essential Oil , Tocopherol Acetate , Coffee CO2.",
        direction:
          " Apply 2 pumps into your palm and spread over the face and neck while massaging it gently. Apply it as last step of your PM routine. ",
        size: "30 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 85,
        type: "Face Oil",
        skinMood: ["Dry Skin", "Melasma", "Sensitive Skin"],
        faq: [
          {
            question: "How often should I use the Midnight Nourishment Blend?",
            answer:
              "We recommend using it nightly as part of your evening skincare routine.",
          },
          {
            question: "Is this product suitable for all skin types?",
            answer:
              "Yes, the blend is formulated to be gentle and beneficial for most skin types.",
          },
          {
            question: "Can I use this product during the day?",
            answer:
              "While it's designed for nighttime use, you can use it during the day in winter weather if you prefer. However, it may feel slightly heavier than typical daytime products.",
          },
          {
            question: "How long does it take to see results?",
            answer:
              "Results may vary, but many users report improvements in skin texture and hydration within a few weeks of consistent use.",
          },
          {
            question: "Are the ingredients natural and cruelty-free?",
            answer:
              "Yes, our blend uses natural, plant-based oils and is not tested on animals.",
          },
        ],
        ingredientsImages: [
          { name: "Bakuchi Oil", image: "bakuchioil.jpg" },
          { name: "Hemp Seed Oil", image: "hempseedoil.jpg" },
          { name: "Borage Seed Oil", image: "borageseedoil.jpg" },
          { name: "Argan Oil", image: "arganoil.jpg" },
          { name: "Coffee Co2", image: "coffeeco2.jpg" },
          { name: "Blue Pea Butterfly Oil", image: "blue Pea1.jpg" },
        ],
      },
      {
        id: "niacinamide-arbutin-fusion",
        image: "niacover.jpg",
        slider: ["nian.jpg", "nian2.jpg", "nia2.jpg"],
        title: "Niacinamide Arbutin Fusion",
        title2: "Niacinamide Arbutin Fusion: Your Path to Radiant Skin",

        description:
          "Niacinamide and Alpha Arbutin work together to even out skin tone and reduce the appearance of dark spots. This fusion helps to inhibit melanin production, leading to a more uniform complexion. Regular use can result in visibly brighter and clearer skin.",
        price: 1350,
        how: "Hydration Boost :Our formula includes Hyaluronic Acid and Glycerin, known for their excellent moisture-retaining properties. These ingredients help to plump up the skin, reducing the appearance of fine lines and wrinkles. The addition of Rose Hydrosol provides a gentle, soothing effect, leaving your skin feeling refreshed and hydrated. | Soothing and Calming :Green Tea and Chamomile extracts in our fusion offer anti-inflammatory benefits. These natural ingredients help to calm irritated skin and reduce redness. Allantoin further enhances the soothing properties, making this product suitable for sensitive skin types. ",
        ingredients:
          "Rose Hydrosol , Niacinamide , Hyaluronic Acid (1%) , Distilled Water , Glycerin , Sodium Lactate , Alpha Arbutin , Green Tea Extract , Chamomile Extract , Geogard ECT , Allantoin , Polyacrylate Crosspolymer-6 , Caesalpinia Spinosa Gum , Sodium Gluconate.",
        direction:
          "Apply a small pea-sized amount on your finger and gently dab it into your skin using a tapping motion.",
        size: "30 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 95,
        type: "Serums",
        skinMood: [
          "Dry Skin",
          "Open Pores",
          "Acne Prone",
          "Melasma",
          "Sensitive Skin",
        ],
        faq: [
          {
            question: "How often should I use this product?",
            answer:
              "For best results, use twice daily, morning and night, after cleansing your face.",
          },
          {
            question: "Is this product suitable for all skin types?",
            answer:
              "Yes, our Niacinamide Arbutin Fusion is formulated to be gentle and effective for all skin types, including sensitive skin.",
          },
          {
            question: "How long does it take to see results?",
            answer:
              "While individual results may vary, many users report seeing improvements in skin tone and texture within 6-12 weeks of consistent use.",
          },
          {
            question: "Can I use this product with other skincare products?",
            answer:
              "Yes, this fusion can be easily incorporated into your existing skincare routine. We recommend applying it before heavier creams or oils.",
          },
          {
            question: "Is this product cruelty-free?",
            answer:
              "Yes, our Niacinamide Arbutin Fusion is cruelty-free and not tested on animals.",
          },
        ],
        ingredientsImages: [
          { name: "Alpha Arbutin", image: "alphaarbutin.jpg" },
          { name: "Chamomile Extract", image: "chamomileextract.jpg" },
          { name: "Allantoin", image: "allantoin.jpg" },
          { name: "Green Tea Extract", image: "greenTea1.jpg" },
        ],
      },
      {
        id: "oat-protein-shine-hair-serum",
        image: "oatcover.jpg",
        slider: ["oatn.jpg", "oatn2.jpg", "oat11.jpg"],
        title: "Oat Protein Shine Hair Serum",
        title2: "Nourish, Smooth, and Revitalize Your Locks",
        incre:
          "Moisture-Boosting Formula: Experience intense hydration with our unique blend of hyaluronic acid and glycerin. These moisture attracting ingredients help to keep your hair soft, smooth, and frizz-free. The addition of xylitol and its derivatives- Aquaxyl(TM) further enhances the serum's ability to retain moisture, leaving your hair looking and feeling revitalized. | Strengthen and Protect:Our serum's oat protein and panthenyl ethyl ether work to strengthen hair fibers from within. The protective properties of these ingredients help to reduce breakage and split ends, promoting healthier, more resilient hair. Regular use of this serum can lead to noticeably stronger and more manageable locks.",
        description2:
          "Our OAT PROTEIN SHINE Hair Serum combines the power of nature with scientific innovation. Featuring rosemary hydrosol, hibiscus extract, and olive oil esters, this serum provides deep nourishment to your hair. The natural ingredients work together to improve hair health, add shine, and protect against environmental damage.",
        description:
          " Discover the power of natural ingredients with our OAT PROTEIN SHINE Hair Serum. Nourish, hydrate, and strengthen your hair while reducing frizz and adding shine. Experience healthier, more manageable locks today.",
        price: 650,
        how: "Nourishes and hydrates hair | Add’s shine and reduces frizz | Strengthens hair fibers | Protects against environmental damage | Improves overall hair health",
        ingredients:
          "Rosemary Hydrosol , Hyaluronic Acid (1%) , Hibiscus Sabdariffa Flower Extract , Glycerin , Olive Oil PEG-7 Esters , Xylitylglucoside (and) Anhydroxylitol (and) Xylitol , Cetyl Trimethyl Ammonium Chloride , Panthenyl Ethyl Ether , Polyquaternium-7 , Hydroxyethyl Cellulose , Benzyl Alcohol (and) Salicylic Acid (and) Glycerin (and) Sorbic Acid , Sodium Gluconate.",
        direction:
          "Apply a small amount to your fingers and distribute it evenly through damp hair strands.",
        size: "30 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 80,
        type: "Serums",
        hairMood: "Suitable for all hair types",
        faq: [
          {
            question: "How often should I use this serum?",
            answer:
              "For best results, apply the serum to damp hair daily or every other day.",
          },
          {
            question: "Is this serum suitable for all hair types?",
            answer:
              "Yes, our OAT PROTEIN SHINE Hair Serum is formulated to benefit all hair types and textures.",
          },
          {
            question: "Can I use this serum on color-treated hair?",
            answer:
              "Yes, this serum is gentle and safe for use on color-treated hair.",
          },
          {
            question: "How long does it take to see results?",
            answer:
              "Many users notice immediate improvements in shine and manageability. For long-term benefits, consistent use over 4-6 weeks is recommended.",
          },
          {
            question: "Is this serum cruelty-free?",
            answer: "Yes, our serum is cruelty-free and not tested on animals.",
          },
        ],
        ingredientsImages: [
          { name: "Aquaxyl(™)", image: "aquaxyl.jpg" },
          {
            name: "Hibiscus Sabdariffa Flower Extract",
            image: "hibiscussabdariffaflowerextract.jpg",
          },
          { name: "Rosemary Hydrosol", image: "rosemaryhydrosol.jpg" },
          { name: "Oat Protein", image: "oatprotein.jpg" },
        ],
      },
      {
        id: "sunshine-nourishing-lotion",
        image: "sunshinecover.jpg",
        slider: ["sunshinen.jpg", "sunshine2.jpg", "sunshine3.jpg"],
        title: "Sunshine Nourishing Lotion",
        title2: "Sunshine Nourishing Lotion: Radiant Skin Elixir",
        incre:
          "Nature's Glow in Every Drop: Sunshine combines the power of natural ingredients to give your skin a healthy, luminous glow. Rose hydrosol and botanical oils work together to nourish and hydrate, leaving your complexion soft and supple. The unique blend of blue pea butterfly extract and hemp seed oil provides antioxidant protection, helping to combat free radicals and environmental stressors  ",

        description:
          " This lightweight formula is suitable for all skin types, including sensitive skin. Jojoba and grapeseed oils closely mimic the skin's natural sebum, ensuring deep moisturization without clogging pores. Sodium PCA and panthenol work to attract and retain moisture, keeping your skin hydrated throughout the day.",
        price: 2150,
        price2: 1500,
        how: "It’s a Moisturizer filled with lots of humectants to provide Hydration and soothes the skin from dryness. It has a Soothing and Protective Formula. Cucumber extract and allantoin calm and soothe irritated skin, reducing redness and inflammation. The addition of vitamin E provides further antioxidant protection, helping to maintain skin elasticity and promote a youthful appearance. With regular use, Sunshine helps create a protective barrier against environmental damage, leaving your skin looking fresh and revitalized.",
        ingredients:
          "Rose Hydrosol , Jojoba Oil , Grapeseed Oil (Infused with Blue Pea Butterfly) , Hemp Seed Oil , Borage Oil , Cetearyl Alcohol , Cetearyl Glucoside , Sodium PCA , Panthenol , Propanediol , Xylitylglucoside (and) Anhydroxylitol (and) Xylitol , Glyceryl Mono Oleate , Cucumber Extract , Geogard ECT (Preservative) , D-Alpha Tocopherol Acetate (Vitamin E) , Allantoin , Sodium Gluconate , Xanthan Gum.",
        direction: "Apply on face and body as required.",
        size: "100 ml",
        size2: "50 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 120,
        type: "Moisturizer",
        skinMood: ["Dry Skin", "Melasma", "Sensitive Skin"],
        faq: [
          {
            question: "Is Sunshine suitable for sensitive skin?",
            answer:
              "Yes, Sunshine is formulated with gentle, natural ingredients suitable for all skin types, including sensitive skin.",
          },
          {
            question: "How often should I use Sunshine?",
            answer:
              "For best results, use Sunshine twice daily, morning and evening, as part of your skincare routine.",
          },
          {
            question: "Is Sunshine cruelty-free?",
            answer: "Yes, Sunshine is cruelty-free and not tested on animals.",
          },
          {
            question: "Can I use Sunshine under makeup?",
            answer:
              "Absolutely! Sunshine absorbs quickly, providing an excellent base for makeup application.",
          },
          {
            question: "How long does a bottle of Sunshine typically last?",
            answer:
              "With regular use, a bottle of 100 ml Sunshine Lotion typically lasts 2 months.",
          },
        ],
        ingredientsImages: [
          { name: "Aquaxyl(™)", image: "aqua.png" },
          { name: "Cucumber Extract", image: "cucumberextract.jpg" },
          { name: "Hemp Seed Oil", image: "hemp1.jpg" },
          { name: "Blue Pea Butterfly", image: "Blue.jpg" },
          { name: "Borage Oil", image: "borage1.jpg" },
        ],
      },
      {
        id: "smooth-essence-conditioner",
        image: "smoothcover.jpg",
        slider: ["smoothn.jpg", "smoothn2.jpg", "smooth2.jpg"],
        title: "Smooth Essence Conditioner",
        title2:
          "Smooth Essence Conditioner: Revitalize Your Locks with Smooth Essence",
        incre:
          "| Botanical Bliss for Your Locks: | : Rose hydrosol provides a gentle, soothing base | : Sunflower and argan oils nourish and protect hair | :  Sal butter adds deep moisture and softness",

        description:
          "Natural Nourishment for Healthy Hair:Our Smooth Essence Conditioner combines the power of nature with scientific innovation to give your hair the care it deserves. Infused with rose hydrosol and natural oils, this conditioner helps restore moisture, enhance shine, and promote overall hair health.",
        price: 1450,
        how: " Your hair will feel silky smooth and look radiantly healthy with regular use of this botanical-rich formula.Science-Backed Ingredients for Optimal Results. Whether you have dry, oily, or normal hair, Smooth Essence Conditioner is formulated to meet your needs |: Panthenol (pro-vitamin B5) strengthens and adds shine | : Oat protein helps repair and protect hair strands | :Glycerin and sodium lactate help balance moisture levels | : Gotu kola extract promotes scalp health | :Allantoin soothes and calms sensitive scalps | These powerful components ensure your hair receives comprehensive care with each application.Experience the difference of a conditioner that caters to your unique hair needs ",
        ingredients:
          "Rose Hydrosol , Cetrimonium Chloride , Sodium Lactate , Sunflower Oil , Argan Oil , Glycerin , Butylene Glycol , Behentrimonium Methosulfate , Cetyl Alcohol , Sal Butter , Panthenol , Glyceryl Mono Oleate , Oat Protein , Gotu Kola Extract , Geogard ECT , D-Alpha Tocopherol Acetate (Vitamin E) , Allantoin , Sodium Gluconate , Guar Gum.",
        direction:
          "Apply on wet hair after wash. You can rinse off or leave on this conditioner for dry, frizzy hair.",
        size: "100 ml",
        emi: "No Cost EMI Available",
        rating: 5,
        reviews: 95,
        type: "Conditioner",
        hairMood: "Suitable for all hair types",
        faq: [
          {
            question: "Is this conditioner suitable for color-treated hair?",
            answer: "Yes, our gentle formula is safe for color-treated hair.",
          },
          {
            question: "How often should I use this conditioner?",
            answer: "For best results, use after every shampoo or as needed.",
          },
          {
            question: "Is this product cruelty-free?",
            answer:
              "Yes, our Smooth Essence Conditioner is cruelty-free and not tested on animals.",
          },
          {
            question: "Can I use this conditioner daily?",
            answer: "Yes, it's gentle enough for daily use if needed.",
          },
          {
            question: "Does this conditioner help with frizz?",
            answer:
              "The nourishing ingredients in our formula help to smooth hair and reduce frizz.",
          },
        ],
        ingredientsImages: [
          { name: "Oat Protein", image: "oatprotein.jpg" },
          { name: "D Panthenol", image: "dpanthenol.jpg" },
          { name: "Sal Butter", image: "salbutter.jpg" },
          { name: "Sodium Lactate", image: "jar.png" },
          { name: "Gotu Kola Extract", image: "gotukolaextract.jpg" },
          { name: "Argan Oil", image: "arganoil.jpg" },
        ],
      },
    ],
  };

  const allSkinMoods = [
    "Open Pores",
    "Dry Skin",
    "Sensitive Skin",
    "Acne Prone",
    "Melasma",
  ];
  const productTypes = [
    "Cleansing",
    "Serums",
    "Moisturizer",
    "Exfoliators",
    "Mask",
    "Eye",
    "Spot Corrector",
    "Face Oil",
  ];

  const filteredProducts = shopData.products.filter((product) => {
    const productTypeMatch =
      selectedProductTypes.length === 0 ||
      selectedProductTypes.some(
        (selectedType) => product.type && product.type.includes(selectedType)
      );

    const skinMoodMatch =
      selectedSkinMoodTypes.length === 0 ||
      (Array.isArray(product.skinMood)
        ? product.skinMood.some((mood) => selectedSkinMoodTypes.includes(mood))
        : selectedSkinMoodTypes.includes(product.skinMood));

    const priceMatch = product.price <= priceRange;

    return productTypeMatch && skinMoodMatch && priceMatch;
  });

  const handleProductTypeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedProductTypes([...selectedProductTypes, value]);
    } else {
      setSelectedProductTypes(
        selectedProductTypes.filter((type) => type !== value)
      );
    }
  };

  const handleSkinMoodChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSkinMoodTypes([...selectedSkinMoodTypes, value]);
    } else {
      setSelectedSkinMoodTypes(
        selectedSkinMoodTypes.filter((mood) => mood !== value)
      );
    }
  };

  const calladmin = () => {
    window.location.href = "tel:+91000000000";
  };

  const openWhatsapp = () => {
    const whatsappUrl =
      "https://api.whatsapp.com/send/?phone=%2B917394011335&text&type=phone_number&app_absent=0";
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  // Helper function to render stars
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0; // Check if there's a half star
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <span key={i} className="star2">
          ★
        </span>
      );
    }

    if (halfStar) {
      stars.push(
        <span key="half" className="star2">
          ☆
        </span>
      ); // Half star represented as ☆
    }

    return stars;
  };

  const openIcard = () => {
    setIsIcardOpen(true); // Open the Icard
  };

  const closeIcard = () => {
    setIsIcardOpen(false); // Close the Icard
  };

  return (
    <>
      <div className="shop-container">
        {/* Sidebar */}
        <aside
          className={`filter-sidebar ${sidebarCollapsed ? "" : "active"}`}
          aria-hidden={sidebarCollapsed}
          id="filter-sidebar"
        >
          <h3>
            <i className="fa fa-filter"></i> Filters
          </h3>

          {/*---- Price Filter 
          <h4>Price</h4>
          <div className="pi">
            <div className="price-range-info">
              <span>{`₹ ${minPrice}`}</span>
              <span>{`₹ ${priceRange}`}</span>
            </div>
            <input
              type="range"
              min={minPrice}
              max={maxPrice}
              value={priceRange}
              onChange={(e) => setPriceRange(e.target.value)}
            />
          </div>
         ----- */}

          {/* Product Type Filter */}
          <h4>Product Types</h4>
          {(shopData.filters.productTypes.length === 1 ||
          productTypes.every((type) =>
            shopData.filters.productTypes.some((item) => item.name === type)
          )
            ? shopData.filters.productTypes
            : productTypes.map((mood) => ({ id: mood, name: mood }))
          ).map((type) => (
            <div key={type.id}>
              <input
                type="checkbox"
                value={type.name}
                onChange={handleProductTypeChange}
                id={`product-type-${type.id}`}
              />
              <label htmlFor={`product-type-${type.id}`}>{type.name}</label>
            </div>
          ))}
          {/* Skin/Mood Type Filter */}
          <h4>Skin Types</h4>
          {(shopData.filters.skinMoodTypes.length === 1 ||
          allSkinMoods.every((mood) =>
            shopData.filters.skinMoodTypes.some((item) => item.name === mood)
          )
            ? shopData.filters.skinMoodTypes
            : allSkinMoods.map((mood) => ({ id: mood, name: mood }))
          ).map((mood) => (
            <div key={mood.id}>
              <input
                type="checkbox"
                value={mood.name}
                onChange={handleSkinMoodChange}
                id={`skin-mood-${mood.id}`}
              />
              <label htmlFor={`skin-mood-${mood.id}`}>{mood.name}</label>
            </div>
          ))}
        </aside>

        {/* Overlay - Only on mobile and sidebar is open */}
        {!sidebarCollapsed && isMobile && (
          <div
            className={`overlay ${!sidebarCollapsed ? "active" : ""}`}
            onClick={closeSidebar}
          ></div>
        )}

        {/* Sidebar Toggle Button */}
        {isMobile && (
          <button
            className="sidebar-toggle"
            onClick={toggleSidebar}
            aria-label={sidebarCollapsed ? "Open Filters" : "Close Filters"}
            aria-expanded={!sidebarCollapsed}
            aria-controls="filter-sidebar"
          >
            {sidebarCollapsed ? <CiFilter /> : "✖"}
          </button>
        )}

        {/* Main Content */}
        <div className="product-gridd">
          <div className="product-grid">
            {filteredProducts.map((product) => (
              <div className="product-card1" key={product.id}>
              
                <Link
                  to={`/shopdetails/${product.id}`}
                  state={{ product }}
                  className="product-card-link"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div className="img-div">
                    <LazyImage
                      src={product.image}
                      alt={product.title}
                      className="product-image1"
                    />
                  </div>
                  <h3 className="title-p1">{product.title}</h3>

                  <p className="description1">
                    {product.description.split(" ").slice(0, 25).join(" ")}{" "}
                    <strong className="st-d">Read More...</strong>
                  </p>
                  <div className={product.price2 && product.size2 ? "fls" : ""}>
                    <div className="fl-p">
                      <div className="price1">{`₹ ${product.price} (${product.size})`}</div>
                    </div>
                    {product.price2 && product.size2 && (
                      <div className="fl-p">
                        <div className="price1">{`₹ ${product.price2} (${product.size2})`}</div>
                      </div>
                    )}
                  </div>

                  <p className="star1">{renderStars(product.rating)}</p>
                </Link>
                <div className="card-btn">
                  <button className="view-product1" onClick={openWhatsapp}>
                    <IoLogoWhatsapp className="btn-ic" />
                  </button>
                  <button className="view-product1" onClick={openIcard}>
                    Consult & Book
                  </button>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>

      {isIcardOpen && <Icard onClose={closeIcard} />}
    </>
  );
};

export default Shop;
