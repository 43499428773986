import React from 'react';
import '../Runner/Runner.css';
import { GiThreeLeaves } from "react-icons/gi";

const Runner = () => {
  const texts = [
    "Paraben-Free",
    "Sulfate-Free",
    "Silicone-Free",
    "Artificial Fragrance-Free",
    "Phthalate-Free",
    "Mineral Oil-Free",
    "No Formaldehyde",
    "Cruelty Free"
  ];

  return (
    <div className="runner-containerr">
      <div className="runner-contentt">
        {/* Duplicate the text array for seamless scrolling */}
        {texts.map((text, index) => (
          <span key={index}>
            {text} <GiThreeLeaves className='ic-runn'/>&nbsp;
          </span>
        ))}
        {texts.map((text, index) => (
          <span key={index + texts.length}>
            {text} <GiThreeLeaves className='ic-runn'/>&nbsp;
          </span>
        ))}
      </div>
    </div>
  );
};

export default Runner;
