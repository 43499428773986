import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import Slider from '../Slider/Slider';
import Modal from '../Modal/Modal';
import Benefits from '../Benifits/Benifits';
import Seller from '../Seller/Seller';
import Category from '../Products/Category';
import Product from '../Products/Products';
import Review2 from '../Review2/Review2';
import '../Home/Home.css';
import Raw from '../Raw/Raw';
import Mimg from '../Mimg/Mimg';
import Faq from '../Faq/Faq';

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showModal, setShowModal] = useState(false);
  
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState(''); // Added state for name error
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  useEffect(() => {
    setShowModal(true);
  }, []);

  const hideModal = () => {
    setShowModal(false);
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setEmailError('Email is required');
      return false;
    } else if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address');
      return false;
    }
    setEmailError('');
    return true;
  };

  const validateName = (value) => {
    const nameRegex = /^[A-Za-z\s]+$/; // Regex to allow only alphabets and spaces
    if (!value) {
      setNameError('Name is required');
      return false;
    } else if (!nameRegex.test(value)) {
      setNameError('Name should only contain alphabets and spaces');
      return false;
    }
    setNameError('');
    return true;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    setName(nameValue);
    validateName(nameValue); // Validate name input on change
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!checkboxChecked) {
      Swal.fire('Please agree to receive updates to proceed');
      return;
    }

    if (!validateEmail(email) || !validateName(name) || isSubmitting) return;

    setIsSubmitting(true);
    const subscriptionMessage = `Message from: Pop up  form`;

    // Prepare the data to send
    const formData = {
      email,
      name,
      from: subscriptionMessage, // Add the dynamic subscription message
    };
    // Send email with both name and email
    emailjs.send('service_m6adkcy', 'template_pbmxkai',formData , 'FE_qmfU7NpslQTD_b')
      .then(() => {
        Swal.fire('Celebrating Our Launch!', 'Thanks for joining us! Enjoy a special 5% discount on your first purchase!', 'success');
        setIsSubmitting(false);
        setEmail('');
        setName('');
        setCheckboxChecked(false); 
        setShowModal(false);
      })
      .catch((error) => {
        Swal.fire('Error', 'Subscription failed. Please try again.', 'error');
        setIsSubmitting(false);
      });
  };

  return (
    <>
     <Helmet>
        <title>Home - 3ESkinRoute</title>
        <meta name="description" content="Nourish Your Skin, Embrace Your Beauty with 3ESkinRoute" />
        <h1>Discover the Best Beauty Products</h1>
      </Helmet>

   
      <Modal show={showModal} handleClose={hideModal}>
        <div className="card-b">
          <div className="left-divv">
            <img src='tlogo.png' alt='logo' className='logo-p-c'/>
            <p className='j-p'>UNLOCK</p>
            <h1 className='j-p-o'>5% OFF </h1>
            <h1 className='j-p-o2'>ON LAUNCH </h1>
            <p className='j-p-p'>Enter your email to avail the discount. Offer valid for first-time customers only.</p>
            
            <input
              type="email"
              placeholder="Enter your Name"
              className="email-input"
              value={name}
              onChange={handleNameChange}
            />
            {nameError && <p className="error-text2">{nameError}</p>} {/* Display name error */}
            
            <input
              type="email"
              placeholder="Enter your email"
              className="email-input"
              value={email}
              onChange={handleEmailChange}
            />
            {emailError && <p className="error-text2">{emailError}</p>} {/* Display email error */}
            
            <label className="checkbox-label">
              <input
                type="checkbox"
                className="checkbox-input"
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
              I agree to receive updates
            </label>
            <button
              className="submit-button2"
              onClick={handleSubmit}
              disabled={isSubmitting || !email || !name || emailError || nameError}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
          <div className="right-divv">
            <img src="beauty3.jpg" alt="Banner" className="banner-image" />
          </div>
          <button className="modal-close-button" onClick={hideModal}></button>
        </div>
      </Modal>

      <Slider />
      <Benefits />
      <Seller />
      <Raw/>
      <div className='category-bg'>
        <Category onCategoryClick={handleCategoryClick} />
      </div>
      <Product selectedCategory={selectedCategory} />
      <Mimg/>
      <Faq/>
      <Review2 />
    </>
  );
};

export default Home;
