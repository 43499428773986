import React,{useState,useEffect} from 'react';
import '../Move/Move.css';
import { Link } from 'react-router-dom';
const Move = () => {
  const data2 = [
    { id: 1, image: 'm1.jpg' },
    { id: 2, image: 'm2.jpg' },
    { id: 3, image: 'm3.jpg' }
  ];
  const data = [
    { id: 1, image: 'm4.jpg' },
    { id: 2, image: 'm5.jpg' },
    { id: 3, image: 'm6.jpg' }
  ];

  const rightMoveData = [
    {
      id: 1,
      title: 'Sustainable Skincare Starts with Plants',
      description1: 'Skin Friendly',
      description2: '3E’s Formula for Healthy and Happy Skin.'
    },
    {
      id: 2,
      title: 'Embrace Your Unique Skin Journey with Custom Plant-Based Solutions',
      description1: 'Natural Radiance',
      description2: ''
    },
    {
      id: 3,
      title: 'Eco-Friendly, Skin-Loving',
      description1: 'Chemical Free',
      description2: 'Plant-Based Skincare with a Conscience.'
    },
    {
      id: 4,
      title: 'Gentle by Nature, Effective by Design',
      description1: 'Plant Nature',
      description2: 'Plant-Based Skincare for Every Skin Type'
    }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        (prevIndex + 1) % rightMoveData.length
      );
    }, 5000); // Change item every 5 seconds

    return () => clearInterval(interval);
  }, [rightMoveData.length]);

  const currentItem = rightMoveData[currentIndex];
  return (
    <>
    <div className="move-container">
      <div className="left-move">
        <div className="image-list top-to-bottom">
          {data.map(item => (
            <div key={item.id} className="image-card">
              <img src={item.image} alt={`${item.id}`} />
            </div>
          ))}
        </div>
        <div className="image-list2 bottom-to-top">
          {data2.map(item => (
            <div key={item.id} className="image-card">
              <img src={item.image} alt={`${item.id}`} />
            </div>
          ))}
        </div>
      </div>
      <div className="right-move">
        <div className="right-move-item">
        <p className='p22'>{currentItem.description1}</p>
          <h1>{currentItem.title}</h1>
         
          <p>{currentItem.description2}</p>
         <Link to="/consultation"><button className='button-17'>Get Consultation</button></Link> 
        </div>
      </div>
      </div>
      </>
  );
};

export default Move;
