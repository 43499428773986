import React from "react";
import "../About/Icon.css";

const Icon = () => {
  return (
    <>
      <div className="full-icon-div">
        <div className="left-icon-div">
          <img src="logo.png" alt="Icon" />
        </div>
        <div className="right-icon-div">
          <h1 className="icon-text">
            Radiance in Every Drop: The Tale Behind Our Brand Mark
          </h1>
        </div>
      </div>
      <div className="full-text-icon-div">
        <p className="text-description">
         <strong>Educate:</strong> We believe in empowering individuals with knowledge about
          their skin and the natural ingredients that can transform it. By
          understanding what works for you, we help you make informed choices
          that align with your skin's needs.
        </p>
        <p className="text-description">
         <strong>Endure:</strong> This process in skincare refers to the commitment and consistency needed
          to achieve long-term results. It means being patient and persistent
          with your routine, understanding that true transformation takes time.
          Endurance involves sticking with a regimen, even when progress seems
          slow, knowing that skincare is a gradual process. It also encompasses
          the resilience of your skin to adapt and improve over time with the
          right care. In essence, endurance is about maintaining dedication to
          your skincare journey for lasting, healthy, and radiant skin.
        </p>
        <p className="text-description">
         <strong>Embrace</strong> in skincare means accepting and celebrating your natural skin,
          including its unique characteristics, imperfections, and needs. It’s
          about loving the skin you're in, rather than striving for unrealistic
          ideals. Embracing skincare encourages a positive relationship with
          your skin, focusing on enhancing its natural beauty and health. It
          also involves adopting a skincare routine that respects and nurtures
          your skin, helping you feel confident and comfortable in your own
          skin.
        </p>
        <p className="text-description">
         <strong>"SkinRoute"</strong>  is the journey you take to achieve healthier, more radiant
          skin. It signifies a personalized skincare routine or regimen tailored
          to your specific needs, guiding you along the way to reach your skin
          goals. The term suggests a thoughtful and intentional approach to
          skincare, where each step is carefully chosen to lead you to the
          desired outcome.
        </p>
        <p className="text-description">
          The concept emphasizes that skincare is not just a one-time solution
          but an ongoing process, with each step contributing to the overall
          health and beauty of the skin. It's about finding the right "route" or
          method that works best for your skin, guiding you to your desired
          results.
        </p>
      </div>
    </>
  );
};

export default Icon;
