import React from 'react';
import { FaLeaf, FaFlask, FaUser } from 'react-icons/fa'; 
import '../Benifits/Benifits.css'; 

const Benefits = () => {
  const benefitsData = [
    {
      icon: <FaLeaf />,
      title: '100% Plant-Based Ingredients',
      description: 'Our products are made from natural, plant-based ingredients that are safe and gentle on your skin.',
      link: "#"
    },
    {
      icon: <FaFlask />,
      title: 'Scientifically Formulated',
      description: 'Developed using science-backed formulas to ensure maximum effectiveness and safety for your skin.',
      link: "#"
    },
    {
      icon: <FaUser />,
      title: 'Personalized Skincare Plans',
      description: 'Tailored skincare solutions designed specifically for your unique skin type and concerns.',
      link: "#"
    }
  ];

  // Handle card click


  return (
    <section className="benefits-section">
      <h2 className="benefits-heading">Pure Mindful & Ethical Skincare</h2>
      <div className="benefits-cards">
        {benefitsData.map((benefit, index) => (
          <div 
            className="benefit-card" 
            key={index} 
          
          >
            <div className="benefit-icon">{benefit.icon}</div>
            <h3 className="benefit-title">{benefit.title}</h3>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Benefits;
