import React from 'react';
import '../Clip/Clip.css'; 

const products = [
  {
    id: 1,
    link:'#',
    title: "Retinyl Palmitate",
    description: `Retinyl Palmitate a gentler form of vitamin A, offers multiple benefits for skincare. It helps boost 
cell turnover, promoting smoother, more youthful skin. This ingredient is also known for reducing 
the appearance of fine lines and wrinkles over time. Its antioxidant properties protect the skin 
from free radical damage, while helping to even out skin tone. Perfect for those looking for a 
mild, yet effective `,
    keyBenefits: [
      "Improves Skin Texture",
      "Boosts Collagen Production",
      "Fights Acne",
      "Gentler than Retinol",
      "Protects Against Free Radical Damage"
    ],
    image: "Retinyl Palmitate1.jpg",
  },
  {
    id: 2,
    link:'#',
    title: "Alpha Glucosyl Hesperidin (AGH)",
    description: `Alpha Glucosyl Hesperidin (AGH)  is a bioflavonoid derived from citrus fruits, particularly 
known for its skin-brightening and anti-inflammatory properties. It helps improve blood 
circulation in the skin, promoting a healthier, more radiant complexion. AGH also has 
antioxidant effects, protecting the skin from environmental damage while reducing redness and 
puffiness. Additionally, it aids in enhancing moisture retention, making the skin look plumper and 
more hydrated. Ideal for revitalizing dull or tired skin, AGH is a versatile ingredient in many 
modern skincare formulations.`,
    keyBenefits: [
      "Boosts Skin Circulation",
      "Brightens and De-puffs",
      "Anti-Aging Powerhouse",
      "Soothes Sensitive Skin",
      "Fights Free Radicals"
    ],
    image: "Alpha Glucosyl Hesperidin.jpg",
  },
  {
    id: 3,
    link:'#',
    title: "Ceramide Complex CLR™ PE-F",
    description: `Ceramide Complex CLR™ PE-F is a plant-derived lipid mixture that strengthens the skin’s 
natural barrier, helping to lock in moisture and prevent dehydration. It replenishes essential 
lipids in the skin, promoting a smoother and more resilient complexion. This complex also helps 
calm and soothe irritated or sensitive skin, making it ideal for those with compromised barriers. 
By restoring balance, it supports a healthy, glowing appearance. A perfect ingredient for 
long-lasting hydration and skin protection. designed to support skin health by restoring its 
natural barrier and enhancing moisture retention `,
    keyBenefits: [
      "Strengthens Skin Barrier",
      "Supports Skin Renewal",
      "Restores Skin's Lipid Barrier",
      "Minimizes the Appearance of Fine Lines and Wrinkles",
      "Prevents Dryness and Irritation"
    ],
    image: "Ceramide Complex CLR™ PE-F.jpg",
  },
  {
    id: 4,
    link:'#',
    title: "Magnesium Ascorbyl Phosphate (MAP)",
    description: `Magnesium Ascorbyl Phosphate (MAP) is a widely celebrated skincare ingredient, particularly loved 
by dermatologists for its stability and effectiveness. It is a gentle, stable form of vitamin C that delivers 
powerful antioxidant benefits to the skin. It helps brighten the complexion by reducing dark spots and 
evening out skin tone. MAP also stimulates collagen production, which improves skin elasticity and 
reduces the appearance of fine lines and wrinkles. Its anti-inflammatory properties soothe the skin, 
making it ideal for sensitive or acne-prone skin. `,
    keyBenefits: [
      "Highly Stable Vitamin C Derivative",
      "Gentle on Sensitive Skin",
      "Skin Brightening Powerhouse",
      "Reduces Hyperpigmentation and Acne Marks",
      "Boosts Collagen Production",
      "Supports Even Skin Texture"
    ],
    image: "(Magnesium Ascorbyl Phosphate) MAP.jpg",
  },
  {
    id: 5,
    link:'#',
    title: "Hydrolyzed Oat Protein",
    description: `Hydrolyzed Oat Protein, derived from the hydrolysis of oat seed protein, is a prized ingredient 
in skincare and haircare formulations. Hydrolyzed oat protein is a fantastic ingredient for 
skincare, known for its exceptional moisturizing properties. It forms a protective barrier on the 
skin, locking in hydration and keeping the skin soft and supple. This protein also helps soothe 
irritation and redness, making it ideal for sensitive or reactive skin types. Additionally, it 
improves skin elasticity and texture, giving a smoother, more refined appearance.`,
    keyBenefits: [
      "Promotes Overall Skin Wellness",
      "Soothes Irritation",
      "Attracts and Retains Moisture",
      "Shields Against Environmental Stressors"
    ],
    image: "Hydrolyzed Oat Protein.jpg",
  },
  {
    id: 6,
    link:'#',
    title: "Potassium Azeloyl Diglycinate",
    description: `Potassium Azeloyl Diglycinate is an innovative derivative of the well-known azelaic acid, 
combined with the hydrating benefits of glycine. Azelaic acid has long been celebrated for its 
remarkable anti-inflammatory, skin-brightening, and anti-acne properties. It is a powerful 
ingredient that effectively targets uneven skin tone and discoloration, promoting a brighter 
complexion. Its anti-inflammatory properties help calm irritation and reduce redness, making it 
suitable for sensitive skin. This ingredient also works to regulate sebum production, helping to 
minimize breakouts and keep skin clear. Additionally, it supports collagen synthesis, improving 
skin elasticity and firmness over time. With consistent use, potassium azeloyl diglycinate 
enhances overall skin clarity and radiance. `,
    keyBenefits: [
      "Regulates Sebum Production",
      "Reduces Redness and Irritation associated with Acne and Rosacea flare-ups",
      "Calms Irritation",
      "Promotes Cell Turnover",
      "Reduces Hyperpigmentation"
    ],
    image: "Potassium Azeloyl Diglycinate.jpg",
  },
  {
    id: 7,
    link:'#',
    title: "Tranexamic Acid",
    description: `Tranexamic Acid is a highly effective skincare ingredient that elevates formulations by 
delivering clearer, more radiant skin. It works by reducing hyperpigmentation, dark spots, and 
discoloration, helping to even out skin tone. Ideal for addressing issues like melasma and 
post-inflammatory pigmentation, it promotes a brighter complexion. Its gentle yet powerful action 
makes it suitable for all skin types, ensuring your products stand out with visible, transformative 
results. `,
    keyBenefits: [
      "Inhibits Melanin Production",
      "Soothes Inflammation",
      "Reduces Sun/Dark Spots",
      "Boosts Skin Radiance",
      "Reduces Post-Inflammatory Hyperpigmentation"
    ],
    image: "Tranexamic Acid.jpg",
  },
  {
    id: 8,
    link:'#',
    title: "SEPIWHITE™ MSH",
    description: `SEPIWHITE™ MSH is a lipo amino acid, it is a phenylalanine biovector and alpha-MSH 
antagonist for guaranteed lightening action. It keeps tyrosinase, the enzyme responsible for 
pigmentation, in a metabolically inactive state, modulating pigment accumulation. After just two 
months of use, the complexion reveals a radiant glow and reduced signs of aging. Users can 
expect a super-fast lightning effect within seven days when combined with AHAs. Additionally, 
SEPIWHITE™ MSH is ECOCERT certified, highlighting its commitment to sustainability. `,
    keyBenefits: [
      "Tyrosinase remains metabolically inactive (alpha-MSH antagonist and pigment accumulation modulator)",
      "Enhances skin tone with a radiant glow, smoothing out signs of aging after 2 months of treatment",
      "Accelerates skin tone improvement when combined with AHAs"
    ],
    image: "SEPIWHITE™MSH.jpg",
  },
  {
    id: 9,
    link:'#',
    title: "SEPICALM VG WP",
    description: `SEPICALM VG WP A proline biovector lipoaminoacid combined with nymphaea alba flower for 
effective balancing and soothing of skin tone. `,
    keyBenefits: [
      "Lightening: Simultaneous action that inhibits both Inflammatory Pigmentation and Basal Pigmentation",
      "Soothing: Specially designed for sensitive or irritated skin, it inhibits inflammatory mediators"
    ],
    image: "SEPICALM VG WP.jpg",
  },
];

const Clip = () => {
  return (
    <div className='full-innnn'>
      <h2 className="incre-heading1">Our Ingredients</h2>
      <div className="product-section5">
        <div className="product-container5">
          {products.map((product) => (
            
            <div key={product.id} className="product-card5" >
              <div className="product-image5">
                <img src={product.image} alt={product.title} />
              </div>
              <div className='cc-c'>
                <h3>{product.title}</h3>
                <p>{product.description}</p>
                {product.keyBenefits && product.keyBenefits.length > 0 && (
                  <div className="key-benefits">
                    <h4>Key Benefits:</h4>
                    <ul>
                      {product.keyBenefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clip;
