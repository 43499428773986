import React,{useState} from "react";

import { FaPlay, FaPause } from 'react-icons/fa';
import { FaEnvelope, FaPhone } from "react-icons/fa";

import "../About/Story.css";
import { Link } from "react-router-dom";
import Swap from "../Swap/Swap";


const Story = () => {
  const [isPlaying, setIsPlaying] = useState({
    video1: false,
    video2: false,
    video3: false,
    video4: false,
    video5: false,
    video6: false,
    video7: false,
    video8: false,
  });

  const togglePlayPause = (videoId) => {
    const videoElement = document.getElementById(videoId);

    if (videoElement.paused) {
      videoElement.play();
      setIsPlaying((prevState) => ({ ...prevState, [videoId]: true }));
    } else {
      videoElement.pause();
      setIsPlaying((prevState) => ({ ...prevState, [videoId]: false }));
    }
  };
  const calladmin = () => {
    const phoneNumber = "tel:+91 7394011335";
    window.open(phoneNumber, "_self");
  };

  const handleClick = () => {
    window.open(`mailto:${"hello@3eskinroute.com"}`);
  };
  return (
    <>
      <div className="full-story">
        <div className="story-section">
          <div className="ll-s">
            <h2 className="text" data-aos="fade-down">
              Founder's Message
            </h2>
            <p className="pp" data-aos="fade-down">
              " Welcome to our plant-based skincare family! As the founder, my journey began with a simple belief: every skin type
              is unique, and so should be the care it receives. After years of
              struggling to find products that truly addressed my skin's needs,
              I realized that a one-size-fits-all approach simply wasn't enough.
              This belief led me to create a line of skincare products that are
              as unique as you are. </p>

              <p className="pp" data-aos="fade-down">  Our mission is to provide you with
              personalized solutions, carefully crafted from nature's finest
              ingredients. We believe that customization is the key to achieving
              healthy, glowing skin, and we're here to help you every step of
              the way. Thank you for trusting us with your skincare journey.
              Together, we'll unlock the beauty that lies within your skin."
            </p>
            <div className="tt">
            <p className="p1" data-aos="fade-down">Warm Regards! </p>
            <p className="p2" data-aos="fade-down">Silky Chopra</p>
            <p className="p3" data-aos="fade-down">(Founder, 3ESkinRoute)</p>
            </div>
         {/*-
            <div className="cards-grid-s">
              {cardData.map((card) => (
                <div className="card-s" data-aos="zoom-in" key={card.id}>
                  <div className="card-content-s">
                    <h3>{card.title}</h3>
                    <p>{card.description}</p>
                  </div>
                  <div className="icon-s">{card.icon}</div>
                </div>
              ))}
            </div>
            -*/}

          </div>
          <div className="rr-s">
            <img src="founder.jpg" data-aos="fade-left" alt="Unique" />
          </div>
        </div>

        <section className="email-section">
          <div className="contact-info">
            <div className="email-info">
              <div className="contact-text" onClick={handleClick}>
                <h2>Email Us</h2>
                <p>hello@3eskinroute.com</p>
              </div>
              <div className="contact-logo">
                <FaEnvelope size={40} color="rgb(0 78 78)" />
              </div>
            </div>
            <div className="call-info" onClick={calladmin}>
              <div className="contact-text">
                <h2>Call Us</h2>
                <p>+91 7394011335</p>
              </div>
              <div className="contact-logo">
                <FaPhone size={40} color="rgb(0 78 78)" />
              </div>
            </div>
          </div>
          <div className="contact-button">
            <Link to="/consultation">
              <button>Consultation</button>
            </Link>
          </div>
        </section>
      </div>
       <Swap/>
       <div className="gallery-container">

       <h1 className="head-pack">Unboxing Stories</h1>
      <div className="gallery-grid">
        {/* First video with play/pause button */}
        <div className="gallery-card card-1" data-aos="fade-right">
          <div className="video-wrapper">
            <video id="video1" src="/pack1.mp4" />
            <div className="controls" onClick={() => togglePlayPause('video1')}>
              {isPlaying.video1 ? <FaPause className="play-pause-icon" /> : <FaPlay className="play-pause-icon" />}
            </div>
          </div>
        </div>

        {/* Second video with play/pause button */}
        <div className="gallery-card card-2" data-aos="fade-down">
          <div className="video-wrapper">
            <video id="video2" src="/pack2.mp4" />
            <div className="controls" onClick={() => togglePlayPause('video2')}>
              {isPlaying.video2 ? <FaPause className="play-pause-icon" /> : <FaPlay className="play-pause-icon" />}
            </div>
          </div>
        </div>

        {/* Third video with play/pause button */}
        <div className="gallery-card card-3" data-aos="fade-left">
          <div className="video-wrapper">
            <video id="video3" src="/pack3.mp4" />
            <div className="controls" onClick={() => togglePlayPause('video3')}>
              {isPlaying.video3 ? <FaPause className="play-pause-icon" /> : <FaPlay className="play-pause-icon" />}
            </div>
          </div>
        </div>

     


        {/* Remaining images 
        <div className="gallery-card card-4" data-aos="fade-right">
          <img src="gs1.jpg" alt="Gallery Image 4" />
        </div>
        <div className="gallery-card card-4" data-aos="fade-right">
          <img src="gs2.jpg" alt="Gallery Image 4" />
        </div>
        <div className="gallery-card card-4" data-aos="fade-right">
          <img src="gs3.jpg" alt="Gallery Image 4" />
        </div>
      */}
      
      </div>
    </div>
    </>
  );
};

export default Story;
