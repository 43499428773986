import React, { useRef, useEffect, useState } from 'react';
import { MdSwapHorizontalCircle } from "react-icons/md";
import '../Swap/Swap.css';

const Swap = () => {
  const sliderRefs = useRef([]); // Create a ref array for sliders
  const beforeRefs = useRef([]); // Create a ref array for before images
  const containerRefs = useRef([]); // Create a ref array for containers
  const [initialized, setInitialized] = useState(false);
  const [widths, setWidths] = useState([]); // Track widths of before images

  // Array of image objects (should be declared before use)
  const images = [
    { after: "p1a.jpeg", before: "p1b.jpeg" },
    { after: "p2a.jpeg", before: "p2b.jpeg" },
    { after: "p3a.jpeg", before: "p3b.jpeg" },
    { after: "p4aa.jpeg", before: "p4b.jpeg" },
   
  
    // Add more image objects here if needed
  ];

  useEffect(() => {
    // Initialize widths state to have the same length as images
    setWidths(Array(images.length).fill(0));
    initializeSlider();
    window.addEventListener('resize', initializeSlider);
    return () => {
      window.removeEventListener('resize', initializeSlider);
    };
  }, []);

  const initializeSlider = () => {
    if (!initialized) {
      images.forEach((_, index) => {
        const containerWidth = containerRefs.current[index]?.offsetWidth || 0;
        const width = containerWidth / 2; // Initial width of before image is half
        if (beforeRefs.current[index]) {
          beforeRefs.current[index].style.width = `${width}px`;
          sliderRefs.current[index].style.left = `${width}px`;
          setWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width; // Set initial width
            return newWidths;
          });
        }
      });
      setInitialized(true);
    }
  };

  const dragTheImg = (e, index) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const containerRect = containerRefs.current[index].getBoundingClientRect();
    const sliderPosition = clientX - containerRect.left;
    const width = Math.max(0, Math.min(sliderPosition, containerRect.width));
    
    beforeRefs.current[index].style.width = `${width}px`;
    sliderRefs.current[index].style.left = `${width}px`;

    // Update the widths state to reflect the current width
    setWidths(prevWidths => {
      const newWidths = [...prevWidths];
      newWidths[index] = width; // Update the width of the current image
      return newWidths;
    });
  };

  return (
    <>
      <h2 className='banda'>Reveal Your Best Skin through Our Products</h2>
      <div className="imgSliderWrapper">
        {images.map((image, index) => (
          <div
            className="imgSliderContainer"
            key={index}
            ref={(el) => (containerRefs.current[index] = el)} // Set the ref for each container
            onMouseMove={(e) => dragTheImg(e, index)} // Pass the index
            onTouchMove={(e) => dragTheImg(e, index)} // Pass the index
          >
            <div className="img-wrapper">
              <div className="img-afterWrap">
                <img className='ba' src={image.after} alt="After" ref={(el) => (sliderRefs.current[index] = el)} />
              </div>
              <div className="img-beforeWrap" ref={(el) => (beforeRefs.current[index] = el)}>
                <img className='bf' src={image.before} alt="Before" />
              </div>
            </div>
            {/* Moving the indicator based on the current width of the before image */}
            <div className="sliderIndicator" ref={(el) => (sliderRefs.current[index] = el)}>
              <MdSwapHorizontalCircle className='sw' size={35} /> {/* You can adjust the size as needed */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Swap;
